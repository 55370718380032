import { clientId, remoteClients, setUserName, clientName, remoteSend, getPostUrl, getUrl, _room } from "../net/messaging.js";
import { Timestamp, updateDoc } from "firebase/firestore";
import { avatars, setCurrentUser } from "../assets/gfx.js";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config.js";
import { game, getNameByClientId } from "../game/gameState.js";
import { Actor, PlayerActor, PlayerStat } from "../game/types.js";
import { _ts } from "../poki.js";
import { GAME_CFG } from "../game/config.js";
import datasPlayer from "../screens/main.js";
import { apiUrl } from "../../../../config.js";
import { creditBonus } from "../graphics/gui.js";
import { mapKeys } from "../game/controls.js";
import { InitKeyboardState } from "./input.js";
import { kill } from "process";
import { raw } from "express";
import { BuildClientVersion, MessageType } from "@iioi/shared/types.js";

//export let bonusCredit = 0.5;

export const getOrCreate = <K, T>(map: Map<K, T>, key: K, fn: (key: K) => T): T => {
    if (!map.has(key)) {
        map.set(key, fn(key));
    }
    return map.get(key);
};

/* @__PURE__ */
export const RGB = (r: number, g: number, b: number) => (r << 16) | (g << 8) | b;

/* @__PURE__ */
export const getLumaColor32 = (luma: number): number => RGB(luma, luma, luma);

/* @__PURE__ */
export const parseRGB = (color: string): number => {
    const len = color.length;
    if (len) {
        if (color.charAt(0) === "#") {
            if (color.length === 4) {
                return RGB(
                    parseInt(color.charAt(1), 16) * 0x11,
                    parseInt(color.charAt(2), 16) * 0x11,
                    parseInt(color.charAt(3), 16) * 0x11,
                );
            } else if (color.length === 7) {
                return RGB(
                    parseInt(color.slice(1, 3), 16),
                    parseInt(color.slice(3, 5), 16),
                    parseInt(color.slice(5, 7), 16),
                );
            }
        }
    }
    return 0;
};

/* @__PURE__ */
export const rgb_scale = (rgb: number, factor: number) =>
    RGB(factor * ((rgb >>> 16) & 0xff), factor * ((rgb >>> 8) & 0xff), factor * (rgb & 0xff));

export const stringToArrayOfObjects = (str: string): any[] => {
    
    let inKey: boolean = true;
    let inValue: boolean = false;
    let inObject: boolean = true;
    let key: string = "";
    let value: string = "";
    let obj: any = {};
    let array: any[] = [];
    
    for(let i = 0 ; i < str.length ; i++)
    {
        if(str[i] == "{")
        {
            inKey = true;
            inObject = true;
        }

        else
        if(str[i] == ":")
        {
            inKey = false;
            inValue = true;
        }

        else
        if(str[i] == "," && inObject)
        {
            inValue = false;
            inKey = true;

            if(value.indexOf("\"") != -1)
            {
                obj[key] = "";
                
                for(let i = 0 ; i < value.length ; i++)
                {
                    if(value[i] != "\"")
                    {
                        obj[key] += value[i];
                    }
                }
            }
            else
            {
                obj[key] = parseInt(value);
            }

            key = "";
            value = "";
        }

        else
        if(str[i] == "}")
        {
            inValue = false;
            inKey = true;

            if(value.indexOf("\"") != -1)
            {
                obj[key] = "";
                
                for(let i = 0 ; i < value.length ; i++)
                {
                    if(value[i] != "\"")
                    {
                        obj[key] += value[i];
                    }
                }
            }
            else
            {
                obj[key] = parseInt(value);
            }

            key = "";
            value = "";
            
            inObject = false;
            
            array.push(obj);

            obj = {};
        }

        if(inKey && str[i] != "\"" && str[i] != ":" && str[i] != "," && str[i] != "{" && str[i] != "}" && str[i] != "[" && str[i] != "]" && !inValue)
        {
            key += str[i];
        }

        else
        if(!inKey && str[i] != ":" && str[i] != "," && str[i] != "{" && str[i] != "}" && str[i] != "]" && str[i] != "]" && inValue)
        {
            value += str[i];
        }
    }

    return array;
};

export const getNameFromName = (name: string): string =>
{
    if(name.indexOf("#") == -1)
    {
        return name;
    }

    const datas = name.split("#");

    return datas[0] ?? name;
}

export const getAvatarFromName = (name: string): number =>
{
    if(name.indexOf("#") == -1)
    {
        return 0;
    }

    const datas = name.split("#");

    return datas[1] ? parseInt(datas[1]) : 0;
}

export const getWeaponFromName = (name: string): number =>
{
    if(name.indexOf("#") == -1)
    {
        return 1;
    }

    const datas = name.split("#");

    return datas[2] ? parseInt(datas[2]) : 1;
}

export const getWeaponSkinFromName = (name: string): number =>
{
    if(name.indexOf("#") == -1)
    {
        return 0;
    }

    const datas = name.split("#");

    return datas[3] ? parseInt(datas[3]) : 0;
}

export const setPseudoFromDatasPlayer = () =>
{
    const rawName: string = getNameFromName(getNameByClientId(clientId));

    setUserName(`${rawName}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
}

export const setPseudoFromWeapon = (client: number, weapon: number) =>
{
    if(client == clientId)
    {
        const rawName: string = getNameFromName(getNameByClientId(client));
        const avatar: number = getAvatarFromName(getNameByClientId(client));
        //const weaponSkin: number = getWeaponSkinFromName(getNameByClientId(client));

        setUserName(`${rawName}#${avatar}#${weapon}#0`);

        //remoteSend(client, MessageType.Name, clientName);

        fetch(getUrl(`cn?v=${BuildClientVersion}&r=${_room._code}`), { method: "POST", body: JSON.stringify([client, `${rawName}#${avatar}#${weapon}#0`]) });
    }
}

export const updateGameStatsDB = async () =>
{
    const stat: PlayerStat = game._state._stats.get(clientId);
    let response;

    if(stat)
    {
        response = await fetch(`${apiUrl}/api/users/update`,
            {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({deads: stat._deads, kills: stat._frags, score: stat._scores, ts: _ts, creditBonus: creditBonus, genesis: avatars[datasPlayer.avatar].id})
            }
        );
    }

    else
    {
        response = await fetch(`${apiUrl}/api/users/update`,
            {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({deads: 0, kills: 0, score: 0, ts: _ts, creditBonus: creditBonus, genesis: avatars[datasPlayer.avatar].id})
            }
        );
    }

    if (response.ok)
    {
        const userData = await response.json();
        setCurrentUser(userData);
    }
    else
    {
        console.error('Erreur de requête:', response.status);
    }

    //console.log(currentUser);

    //document.cookie = "bones_bonus_credit=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    /*console.log(bonusCredit);

    if(userId != "")
    {
        const userDocRef = doc(db, "users", userId);

        //console.log(userDocRef);

        getDoc(userDocRef).then(docSnapshot =>
        {
            //console.log(docSnapshot.data());
            
            if(docSnapshot.exists())
            {
                const userData = docSnapshot.data();

                if(stat)
                {
                    userData.deads += stat._deads;
                    userData.kills += stat._frags;
                    userData.score += stat._scores;
                    userData.credits = userData.credits ? userData.credits + stat._scores * bonusCredit : stat._scores * bonusCredit;
                }

                userData.time_game += (Date.now() - _ts) / 1000;

                updateDoc(userDocRef, userData);
            }
        });
    }*/

    //console.log(game._state._stats.get(clientId)._time.seconds);
}

export const createCookieBonusCredit = async () =>
{
    /*if(!avatars[datasPlayer.avatar].free)
    {
        const res = await fetch(`${apiUrl}/api/nft/avatars`,
        {
            method: 'GET',
            credentials: 'include'
        });

        const metadatas = await res.json();

        //console.log(metadatas);
        
        metadatas.map((m: any) =>
        {
            if(m.token_id == avatars[datasPlayer.avatar].id)
            {
                const params: any[] = JSON.parse(m.metadata).attributes;

                params.map((p: any) =>
                {
                    if(p.trait_type == "Credit Bonus")
                    {
                        //console.log(p.value);

                        bonusCredit = p.value;
                    }
                });
            }
        });
    }*/

    //document.cookie = "bones_credit_bonus" + "=" + bonusCredit + ";path=/";
}

export const avatarsAccessibles = async () =>
{
    /*fetch("http://localhost:3000/api/nft/avatars",
    {
        method: 'GET',
        credentials: 'include'
    })
    .then((res) =>
    {
        res.json().then((ids) =>
        {
            for(let i = 0 ; i < avatars.length ; i++)
            {
                if(!avatars[i].free)
                {
                    if(ids.indexOf(avatars[i].id) != -1)
                    {
                        avatars[i].accessible = true;
                    }
                }
            }
        });

        //console.log("CONNECTED");
    })
    .catch((err) =>
    {
        console.error(err);
    });*/

    const res = await fetch(`${apiUrl}/api/nft/avatars`,
    {
        method: 'GET',
        credentials: 'include'
    });

    const metadatas = await res.json();

    for(let i = 0 ; i < avatars.length ; i++)
    {
        if(!avatars[i].free)
        {
            metadatas.map((m: any) =>
            {
                if(m.token_id == avatars[i].id)
                {
                    avatars[i].accessible = true;
                }
            });
        }
    }
}

export const getCatSelected = (indexWeaponCat: number): number =>
{
    let catSelect: number = -1;
    
    const linkIndicesWeapons =
    [
        {
            indexIconCat: 21,
            indexWeapon: 8
        },

        {
            indexIconCat: 22,
            indexWeapon: 1
        },

        {
            indexIconCat: 23,
            indexWeapon: 2
        },

        {
            indexIconCat: 24,
            indexWeapon: 7
        },

        {
            indexIconCat: 25,
            indexWeapon: 3
        },

        {
            indexIconCat: 26,
            indexWeapon: 4
        },

        {
            indexIconCat: 27,
            indexWeapon: 10
        },

        {
            indexIconCat: 28,
            indexWeapon: 5
        },

        {
            indexIconCat: 29,
            indexWeapon: 6
        },

        {
            indexIconCat: 30,
            indexWeapon: 9
        },
    ];

    for(const l of linkIndicesWeapons)
    {
        if(l.indexIconCat == indexWeaponCat)
        {
            catSelect = l.indexWeapon;
        }
    }

    return catSelect;
};

export const loadKeysSettings = () =>
{
    //console.log(mapKeys);

    if(localStorage.getItem("bones_keys"))
    {
        const lines: string[] = localStorage.getItem("bones_keys").split("\n");

        lines.map((line: string) =>
        {
            if(line != "")
            {
                const [key, value] = line.split("=");

                //console.log(key, value);
                mapKeys.set(key, JSON.parse(value));
            }
        });
    }

    InitKeyboardState();
}

export const convertScoreToTable = (values: any[]): string =>
{
    let res =
    `<thead>
        <tr>
        <th scope="col" style="border: 1px solid lightcyan">id</th>
        <th scope="col" style="border: 1px solid lightcyan">name</th>
        <th scope="col" style="border: 1px solid lightcyan">score</th>
        <th scope="col" style="border: 1px solid lightcyan">deads</th>
        <th scope="col" style="border: 1px solid lightcyan">kills</th>
        </tr>
    </thead>`;

    res += "<tbody>";

    values.map((v: any) =>
    {
        res +=
        `<tr>
            <th scope="row" style="border: 1px solid lightcyan">${v.id}</th>
            <td style="border: 1px solid lightcyan">${v.name}</td>
            <td style="border: 1px solid lightcyan">${v.score}</td>
            <td style="border: 1px solid lightcyan">${v.deads}</td>
            <td style="border: 1px solid lightcyan">${v.kills}</td>
        </tr>`;
    });

    res += "</tbody>";

    return res;
}
