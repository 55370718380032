import { doc } from "firebase/firestore";
import { game } from "../game/gameState.js";
import { clientId } from "../net/messaging.js";
import { poki } from "../poki.js";
import { updateGameStatsDB } from "../utils/utils.js";

export let isModalPopupActive:any;

let popupResolve: undefined | ((v: string) => void);
let popupReject: undefined | ((err: string) => void);
export let isModalClosed = true;

export const getIsModalClosed = (): boolean => {
    return isModalClosed;
};

export const setIsModalClosed = (value: boolean) => {
    isModalClosed = value;
};

/*export const onInitModal = () => {
    isModalClosed = false;
};*/

const closeModalPopup = (accepted?: boolean) => {
    const popup = document.getElementById("popup");
    const input = document.getElementById("popupInput") as HTMLInputElement;
    const value = input.value ? input.value : "";
    if (input.type !== "hidden") {
        input.blur();
    }
    // restore focus to canvas
    c.focus();

    const resolve = popupResolve;
    const reject = popupReject;
    popupResolve = undefined;
    popupReject = undefined;
    if (accepted) {
        if (resolve) {
            resolve(value);
        }
    } else {
        if (reject) {
            reject("dismiss");
        }
    }
    if (popup) {
        popup.style.opacity = "0";
        popup.style.pointerEvents = "none";
        setTimeout(() => {
            popup.style.visibility = "hidden";
            input.type = "hidden";
        }, 300);
        const popupFrame = document.getElementById("popupFrame");
        if (popupFrame) {
            popupFrame.style.transform = "translate(-50%,-100%)";
        }
    }
    isModalPopupActive = false;
};

export const handleModalKeyEvent = (e: KeyboardEvent): boolean | undefined => {
    if (e.key === "Enter") {
        closeModalPopup(true);
        return false;
    } else if (e.key === "Escape") {
        closeModalPopup(false);
        return false;
    }
};

export const modalPopup = (options: {title: string; desc: string; value?: string}): Promise<string> => {
    document.getElementById("popupTitle").innerText = options.title;
    document.getElementById("popupDesc").innerText = options.desc;

    return new Promise((resolve, reject) => {
        popupResolve = resolve;
        popupReject = reject;
        openModalPopup(options.value);
    });
};

export const modalScorePopup = (options: {title: string; desc: string}): Promise<string> => {
    document.getElementById("popupTitle").innerText = options.title;
    document.getElementById("popupDesc").innerText = "";
    document.getElementById("popupTable").innerHTML = options.desc;

    return new Promise((resolve, reject) => {
        popupResolve = resolve;
        popupReject = reject;
        openModalScorePopup();
    });
};

export const modalMessagePopup = (options: {title: string; desc: string; value?: string}): Promise<string> => {
    document.getElementById("popupTitle").innerText = options.title;
    document.getElementById("popupDesc").innerText = options.desc;

    return new Promise((resolve, reject) => {
        popupResolve = resolve;
        popupReject = reject;
        openModalMessagePopup(options.value);
    });
};

const openModalScorePopup = () => {
    isModalPopupActive = true;
    const popup = document.getElementById("popup");
    if (popup) {
        popup.style.pointerEvents = "auto";
        popup.style.visibility = "visible";
        popup.style.opacity = "1";
        const popupFrame = document.getElementById("popupFrame");
        if (popupFrame) {
            popupFrame.style.transform = "translate(-50%,-50%)";
        }
    }
};

const openModalPopup = (inputValue?: string) => {
    isModalPopupActive = true;
    const popup = document.getElementById("popup");
    if (popup) {
        popup.style.pointerEvents = "auto";
        popup.style.visibility = "visible";
        popup.style.opacity = "1";
        const popupFrame = document.getElementById("popupFrame");
        if (popupFrame) {
            popupFrame.style.transform = "translate(-50%,-50%)";
        }
        const input = document.getElementById("popupInput") as HTMLInputElement;
        if (inputValue != null) {
            input.type = "text";
            input.value = inputValue;
            input.style.visibility = "visible";
            input.focus();
        } else {
            input.type = "hidden";
            input.style.visibility = "hidden";
        }
    }
};

const openModalMessagePopup = (inputValue?: string) => {
    isModalPopupActive = true;
    const popup = document.getElementById("popup");
    if (popup) {
        popup.style.pointerEvents = "auto";
        popup.style.visibility = "visible";
        popup.style.opacity = "1";
        const popupFrame = document.getElementById("popupFrame");
        if (popupFrame) {
            popupFrame.style.transform = "translate(-50%,-50%)";
        }
    }
};

const closeTableAction = () =>
{
    //console.log("Close table");

    document.getElementById("rooms_list_div").style.visibility = "hidden";
    document.getElementById("rooms_list_div").style.opacity = "0";
}

const onBackdropClicked = e => {
    if (e.eventPhase === Event.AT_TARGET) {
        closeModalPopup();
    }
};

const onAcceptClicked = () => {
    //
    closeModalPopup(true);
};

const onDiscardClicked = () => {
    //
    closeModalPopup(false);
};

const onCloseClicked = () => {
    isModalClosed = true;
    closeModalPopup(false);
    poki._gameplayStart();

    updateGameStatsDB();

    game._state._stats.set(clientId, { _scores: 0, _frags: 0, _deads: 0, _bonus: false, _ts: Date.now() });
};

export const initModals = () => {
    //isModalClosed= false;
    const d = document;
    const backdrop = d.createElement("div");
    backdrop.id = "popup";
    backdrop.style.visibility = "hidden";
    backdrop.style.opacity = "0";
    backdrop.style.position = "absolute";
    backdrop.style.top = "0px";
    backdrop.style.left = "0px";
    backdrop.style.width = "100%";
    backdrop.style.height = "100%";
    backdrop.style.backgroundColor = "rgba(0,0,0,0.7)";
    backdrop.style.pointerEvents = "none";
    //backdrop.style.backdropFilter = "blur(8px)";
    backdrop.style.transition = "visibility 0s, opacity 0.15s 0.05s ease-in";
    backdrop.onclick = onBackdropClicked;
    d.body.appendChild(backdrop);

    const layer = d.createElement("div");
    backdrop.appendChild(layer);
    layer.id = "popupFrame";
    layer.style.position = "absolute";
    layer.style.left = "50%";
    layer.style.top = "50%";
    layer.style.width = "50%";
    layer.style.transform = "translate(-50%,-100%)";
    layer.style.backgroundColor = "#11151B";
    layer.style.boxShadow = "0px 4px 8px rgba(0,0,0,1)";
    layer.style.borderRadius = "16px";
    layer.style.textAlign = "center";
    layer.style.fontFamily = "monospace";
    layer.style.padding = "10px";
    layer.style.border = "solid";
    layer.style.borderColor = "#ccc";
    layer.style.borderWidth = "1pt";
    layer.style.transition = "transform 0.2s ease-in-out";

    const title = d.createElement("h1");
    title.id = "popupTitle";
    title.style.color = "white";
    title.innerText = "Title";
    title.style.margin = "0";
    layer.appendChild(title);

    const desc = d.createElement("p");
    desc.id = "popupDesc";
    desc.innerText = "Long long description";
    desc.style.color = "lightcyan";
    layer.appendChild(desc);

    const table = d.createElement("table");
    table.id = "popupTable";
    table.innerText = "Long long table";
    table.style.color = "lightcyan";
    table.style.borderCollapse = "collapse";
    table.style.margin = "0 auto";
    table.style.width = "100%";
    table.style.textAlign = "center";
    layer.appendChild(table);

    const form = d.createElement("form");
    form.style.minWidth = "90%";
    form.style.width = "90%";
    layer.appendChild(form);
    form.onsubmit = onAcceptClicked;
    form.oncancel = () => {
        console.log("cancel");
    };

    const input = d.createElement("input");
    input.id = "popupInput";
    input.type = "hidden";
    input.style.minWidth = "90%";
    input.style.width = "90%";
    input.style.padding = "8px 16px";
    input.style.fontFamily = "monospace";
    input.style.background = "none";
    input.style.borderRadius = "16px";
    input.style.color = "#fff";
    input.style.outline = "inherit";
    input.style.borderColor = "#fff";
    input.style.borderWidth = "2pt";
    form.appendChild(input);

    const buttonsLine = d.createElement("p");
    buttonsLine.style.width = "100%";
    layer.appendChild(buttonsLine);

    // const accept = d.createElement("button");
    // accept.innerText = "OK";
    // accept.style.float = "right";
    // accept.style.padding = "8px 16px";
    // accept.style.marginLeft = "8px";
    // accept.style.fontFamily = "monospace";
    // accept.style.background = "none";
    // accept.style.borderRadius = "16px";
    // accept.style.color = "#fff";
    // accept.style.outline = "inherit";
    // accept.style.borderColor = "#fff";
    // accept.style.borderWidth = "2pt";
    // accept.onclick = onAcceptClicked;
    // buttonsLine.appendChild(accept);

    const closeButton = d.createElement("button");
    closeButton.innerText = "X";
    closeButton.style.position = "absolute";
    closeButton.style.top = "10px";
    closeButton.style.right = "10px";
    closeButton.style.background = "none";
    closeButton.style.border = "none";
    closeButton.style.color = "#fff";
    closeButton.style.fontSize = "20px";
    closeButton.style.cursor = "pointer";
    closeButton.onclick = onCloseClicked;
    layer.appendChild(closeButton);

    //////////////////////////////////////////////

    const roomsListDiv = d.createElement("div");
    roomsListDiv.id = "rooms_list_div";
    //roomsList.onclick = onBackdropClicked;
    roomsListDiv.style.visibility = "hidden";
    roomsListDiv.style.opacity = "0";
    d.body.appendChild(roomsListDiv);
    
    const closeTable = d.createElement("button");
    closeTable.id = "rooms_list_close";
    closeTable.innerText = "X";
    closeTable.style.position = "absolute";
    closeTable.style.top = "100px";
    closeTable.style.right = "25%";
    closeTable.onclick = closeTableAction;
    roomsListDiv.appendChild(closeTable);

    const roomsListTable = d.createElement("table");
    roomsListTable.id = "rooms_list_table";
    roomsListTable.style.position = "absolute";
    roomsListTable.style.top = "100px";
    roomsListTable.style.left = "25%";
    roomsListTable.style.width = "50%";
    //roomsListTable.style.height = "100%";
    roomsListTable.style.backgroundColor = "rgba(0,0,0,0.7)";
    roomsListTable.style.pointerEvents = "none";
    //backdrop.style.backdropFilter = "blur(8px)";
    roomsListTable.style.transition = "visibility 0s, opacity 0.15s 0.05s ease-in";
    roomsListTable.style.borderCollapse = "collapse";
    roomsListDiv.appendChild(roomsListTable);

    // const cancel = d.createElement("button");
    // cancel.innerText = "Cancel";
    // cancel.style.float = "right";
    // cancel.style.padding = "8px 16px";
    // cancel.style.marginLeft = "8px";
    // cancel.style.fontFamily = "monospace";
    // cancel.style.color = "#f00";
    // cancel.style.outline = "inherit";
    // cancel.style.background = "none";
    // cancel.style.border = "solid";
    // cancel.style.borderRadius = "16px";
    // cancel.style.borderColor = "#f00";
    // cancel.style.borderWidth = "2pt";
    // cancel.onclick = onDiscardClicked;
    // buttonsLine.appendChild(cancel);
};
