import { button, InitMainBarr, InitUserBarr, label, ui_begin, ui_finish, uiState, fight, setFight, showJoinGame, setShowJoinGame, showCreateGame, startGame, setStartGame, setShowCreateGame, disableCatSel, disableSkinSel, disableIconSel } from "../graphics/gui.js";
import { clientName, connect, setUserName } from "../net/messaging.js";
import { enableSettingsFlag, SettingFlag } from "../game/settings.js";
import { keyboardDown, KeyCode } from "../utils/input.js";
import { BuildClientVersion, GameModeFlag, IsPokiBuild, NewGameParams, RoomsInfoResponse, } from "@iioi/shared/types.js";
import { guiSettingsPanel } from "./settingsPanel.js";
import { guiDevModePanel } from "./devModePanel.js";
import { logScreenView, setPlayerName } from "../analytics.js";
import { getIsModalClosed } from "../modals/index.js";
import { L } from "../assets/text.js";
import { guiLanguagesPanel } from "./languages.js";
import { Img } from "../assets/img.js";
//import {avatars, weapons} from "../assets/gfx.js";
import { avatars, currentUser, datasPlayer, img, items, logo } from "../assets/gfx.js";
import { GAME_CFG } from "../game/config.js";
import { signInWithCustomToken, UserCredential, signOut } from "firebase/auth";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { auth, db } from "../firebase/firebase-config.js";
import { clientId } from "../net/messaging.js";
import { game, getNameByClientId } from "../game/gameState.js";
import { PlayerActor, PlayerStat } from "../game/types.js";
import { Magic } from 'magic-sdk';
import { avatarsAccessibles, getNameFromName, RGB } from "../utils/utils.js";
import { draw, drawMeshSpriteUp, flush, gl, gl_program, setDrawZ } from "../graphics/draw2d.js";
import { gl_program_menu } from "../graphics/draw2d.js";
import { drawPlayerOpaque } from "../game/gameDraw.js";
import { WORLD_SCALE } from "../assets/params.js";
import { gameCamera } from "../game/camera.js";
import { apiUrl } from "../../../../config.js";
import { modalMessagePopup, modalPopup, modalScorePopup } from "../modals/index.js";
import { parseRadix64String } from "@iioi/shared/radix64.js";
import { mapKeys } from "../game/controls.js";
import { displayKey, selecReload, selectDown, selectDrop, selectFast, selectJump, selectLeft, selectRight, selectSwap, selectUp } from "./keys.js";

export let selectMenu =
{
    avatar: 0,
    weapon: 1,
    weaponSkin: 0
};

let actionToEdit: string = "";

//export let gameStartAt: Timestamp = Timestamp.now();

/*function signin(email: string): void
{
    const magic = new Magic("pk_live_46AFD7BD07A7237F");

    try
    {
        magic.auth.loginWithMagicLink({ email: email }).then(async (res: any) =>
        {
            const currentUser = await magic.user.getMetadata();
            console.log(currentUser.publicAddress);
            
            fetch("/auth",
            {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json",
                    //"Authorization": `Bearer ${idToken}`
                },
                body: JSON.stringify({ userId: currentUser.publicAddress, username: getNameByClientId(clientId) })
            }).then(response => {
                //
            });
            
        }).catch((error) =>
        {
            console.log("ERROR MAGIC LINK : " + error);
        });
    }
    catch (error)
    {
        console.error('Erreur de connexion Magic:', error);
    }
}*/

const viewport =
{
    w: 300,
    h: 250
};

export const Menu = {
    Main: 0,
    Settings: 1,
    Dev: 2,
    Games: 3,
    Practice: 4,
    CreateGame: 5,
    Language: 6,
    Avatar: 7,
    Weapon: 8,
    WeaponSkin: 9,
    Keys: 10
} as const;
export type Menu = (typeof Menu)[keyof typeof Menu];

export const MenuCommand = {
    StartPractice: 0,
    QuickStart: 1,
    JoinGame: 2,
    CreateGame: 3,
    Replay: 4,
} as const;
export type MenuCommand = (typeof MenuCommand)[keyof typeof MenuCommand];

export interface MenuResult {
    _command: MenuCommand;
    _newGame?: NewGameParams;
    _joinByCode?: string;
}

export const NPC_LEVELS = ["none", "rare", "normal", "crowd"];

export let menu: Menu = Menu.Main;
export const setMenu = (value: any) =>
{
    menu = value;
}
let devLock = 0;

// create game options
export const newGameSettings: MenuResult = {
    _command: MenuCommand.CreateGame,
    _newGame: {
        _flags: GameModeFlag.Public,
        _playersLimit: 8,
        _npcLevel: 2,
        _theme: 0,
    },
};

export const newPracticeSettings: MenuResult = {
    _command: MenuCommand.StartPractice,
    _newGame: {
        _flags: GameModeFlag.Offline,
        _playersLimit: 1,
        _npcLevel: 2,
        _theme: 0,
    },
};

export let menuProfil: boolean = false;
export const showMenuProfil = (value: boolean) =>
{
    menuProfil = value;
}

export let menuInventory: boolean = false;
export const showMenuInventory = (value: boolean) =>
{
    menuInventory = value;
}

export let menuBallonFight: boolean = false;
export const showBallonFight = (value: boolean) =>
{
    menuBallonFight = value;
}

const hideMenus = () =>
{
    menuBallonFight = false;
    menuInventory = false;
    menuProfil = false;
    setShowCreateGame(false);

    disableCatSel();
    disableSkinSel();
    disableIconSel();
}

let roomToJoin: string = "";

export const menuScreen = (roomsInfo: RoomsInfoResponse): MenuResult | undefined => {
    //console.log(window.innerWidth, self.innerWidth);
    
    let result: MenuResult | undefined;

    //console.log(datasPlayer.weapon);

    ui_begin();
    if(fight)
    {
        menuBallonFight = false;
        setFight(false);
        
        if(roomsInfo.rooms.length == 0)
        {
            result = { _command: MenuCommand.QuickStart };
        }

        else
        {
            const maxRooms: number = 10;
            
            document.getElementById("rooms_list_div").style.visibility = "visible";
            document.getElementById("rooms_list_div").style.opacity = "1";

            let tableContent: string =
            `<caption style="color: white;">
                Rooms list
            </caption>
            <thead style="color: white;">
                <tr>
                    <th scope="col">Room code</th>
                    <th scope="col">Players number</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>`;
            
            tableContent += "<tbody style=\"color: white;\">";

            for(let i: number = 0 ; i < maxRooms ; i++)
            {
                if(i <= roomsInfo.rooms.length - 1)
                {
                    tableContent +=
                    `<tr style="height: 20px">
                        <th scope="col" style="border: 1px solid white; padding: 8px;">${roomsInfo.rooms[i].code}</th>
                        <th scope="col" style="border: 1px solid white; padding: 8px;">${roomsInfo.rooms[i].players}</th>
                        <th scope="col" style="border: 1px solid white; padding: 8px;"><button class="join" id=${roomsInfo.rooms[i].code} style="pointer-events: auto;">Join</button></th>
                    </tr>`;
                }

                else
                {
                    tableContent +=
                    `<tr style="height: 20px">
                        <th scope="col" style="border: 1px solid white; padding: 8px;">N/A</th>
                        <th scope="col" style="border: 1px solid white; padding: 8px;">0</th>
                        <th scope="col" style="border: 1px solid white; padding: 8px;">N/A</th>
                    </tr>`;
                }
            }

            tableContent += "</tbody>";

            document.getElementById("rooms_list_table").innerHTML = tableContent;

            const els = document.getElementsByClassName("join");

            Array.prototype.forEach.call(els, function(el: any)
            {
                el.addEventListener("click", () =>
                {
                    //Join room
                    //console.log(el.id);
                    roomToJoin = el.id;
                    //result = { _command: MenuCommand.JoinGame, _joinByCode: el.id };
                });
            });

            //document.getElementById("rooms_list_close").addEventListener("click", () => console.log("clic"));
        }
    }

    if(roomToJoin != "")
    {
        result = { _command: MenuCommand.JoinGame, _joinByCode: roomToJoin };
        roomToJoin = "";
        document.getElementById("rooms_list_div").style.visibility = "hidden";
        document.getElementById("rooms_list_div").style.opacity = "0";
    }
    
    if(startGame)
    {
        if(clientName.indexOf("#") == -1)
        {
            setUserName(`${clientName}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
        }
        else
        {
            setUserName(`${getNameFromName(clientName)}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
        }

        setStartGame(false);

        hideMenus();
        
        result = newGameSettings;
    }

    {
        const W = uiState._width;
        const H = uiState._height;
        const centerX = W >> 1;
        const centerY = H >> 1;
        //let modal = getIsModalClosed();

        if (menu === Menu.Main) {

            /*if (stat._played) {
                if(!modal){
                    modalScorePopup({
                        title: "Scores",
                        desc: `Score : ${String(stat._scores)}
                               Kills : ${String(stat._frags)}
                               Deads : ${String(stat._deads)}`,
                    })
                }
            }*/

            if(showJoinGame)
            {
                /*modalPopup({ title: "JOIN BY CODE", desc: "", value: "" }).then((code) =>
                {
                    const ri = code.lastIndexOf("r=");
                    if (ri) {
                        code = code.substring(ri + 2);
                    }
                    const v = parseRadix64String(code);
                    if (v) {
                        //joinRoomByCode(code);
                    } else {
                        console.warn("bad game code");
                    }
                });*/

                result = { _command: MenuCommand.JoinGame };

                setShowJoinGame(false);
            }

            let totalJoinCap = 0;
            for (const room of roomsInfo.rooms) {
                totalJoinCap += room.max - room.players;
            }

            //label(L("welcome_back"), 7, centerX, 14);
            
            /*if (button("replay", "📂 " + L("open_replay"), centerX - 80 / 2, centerY - 80, { w: 80 })) {
                result = { _command: MenuCommand.Replay };
            }*/

            //console.log(roomsInfo);

            if (roomsInfo.players) {
                label(`${roomsInfo.players} are playing now`, 7, centerX, centerY + 45);
            }

            /*if (button("dev_mode", "", centerX - 40, centerY - 40, { w: 80, h: 80, visible: false })) {
                if (++devLock > 3) {
                    enableSettingsFlag(SettingFlag.DevMode);
                    menu = Menu.Dev;
                    logScreenView("dev_screen");
                }
            }*/

            const mainButtons = InitMainBarr(W, H);

            drawMeshSpriteUp(
                logo,
                0,
                0,
                40,
                0,
                1,
                1,
                1,
                0xffffff,
            );

            if (
                button(
                    mainButtons[0]._name,
                    mainButtons[0]._text,
                    mainButtons[0]._x, mainButtons[0]._y, { w: mainButtons[0]._w, h: mainButtons[0]._h, visible: true, color: 0x171c22, icon: { ...items[3], big: document.body.clientWidth > 700 ? false : true }, textColor: 0x8996A3 },
                ) && !showJoinGame && !showCreateGame
            ) {
                //console.log(datasPlayer);

                if(clientName.indexOf("#") == -1)
                {
                    setUserName(`${clientName}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
                }
                else
                {
                    setUserName(`${getNameFromName(clientName)}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
                }

                menuProfil = false;
                menuInventory = false;
                
                if(currentUser)
                {
                    menuBallonFight = !menuBallonFight;
                }

                else
                {
                    modalMessagePopup({ title: "Oups" , desc: "Please login if you want to play"});
                }

                //result = { _command: MenuCommand.QuickStart };
            }

            if (
                button(
                    mainButtons[1]._name,
                    mainButtons[1]._text,
                    mainButtons[1]._x, mainButtons[1]._y, { w: mainButtons[1]._w, h: mainButtons[1]._h, visible: true, color: 0x171c22, icon: { ...items[4], big: document.body.clientWidth > 700 ? false : true }, textColor: 0x8996A3 },
                ) && !showJoinGame && !showCreateGame
            ) {
                menuProfil = false;
                menuBallonFight = false;
                
                menuInventory = !menuInventory;
            }

            if (
                button(
                    mainButtons[2]._name,
                    mainButtons[2]._text,
                    mainButtons[2]._x, mainButtons[2]._y, { w: mainButtons[2]._w, h: mainButtons[2]._h, visible: true, color: 0x171c22, icon: { ...items[5], big: document.body.clientWidth > 700 ? false : true }, textColor: 0x8996A3 },
                ) && !showJoinGame && !showCreateGame
            ) {
                //document.location.href = apiUrl;
                window.open(apiUrl, "_blank");
            }

            if (
                button(
                    mainButtons[3]._name,
                    mainButtons[3]._text,
                    mainButtons[3]._x, mainButtons[3]._y, { w: mainButtons[3]._w, h: mainButtons[3]._h, visible: true, color: 0x171c22, icon: { ...items[6], big: document.body.clientWidth > 700 ? false : true }, textColor: 0x8996A3 },
                ) && !showJoinGame && !showCreateGame
            ) {
                hideMenus();
                
                menu = Menu.Settings;
                logScreenView("settings_screen");
            }

            //////////////////////////////////////////////////////////////////////////////////

            const userButtons = InitUserBarr(W, H);

            /*uiState._imgOps.push({ texture: avatars[datasPlayer.avatar].texture, _w: 0.1, _h: 0.1, _x: userButtons[0]._x + 6, _y: userButtons[0]._y + userButtons[0]._h/2 });
            uiState._imgOps.push({ texture: img[Img.box_lt], _w: 0.2623 * userButtons[0]._w, _h: userButtons[0]._h, _x: userButtons[0]._x, _y: userButtons[0]._y, color: 0x171c22 });*/

            if (
                button(
                    userButtons[0]._name,
                    userButtons[0]._text,
                    userButtons[0]._x, userButtons[0]._y, { w: userButtons[0]._w, h: userButtons[0]._h, visible: true, color: 0xf5f7fa },
                ) && !showJoinGame && !showCreateGame
            ) {
                /*modalPopup({
                    title: L("your_name"),
                    desc: L("change_name_desc"),
                    value: getNameFromName(clientName),
                })
                .then(v => {
                    setUserName(v);
                })
                .catch(() => {
                    //
                });*/

                menuBallonFight = false;
                menuInventory = false;

                menuProfil = !menuProfil;
            }

            if (
                button(
                    userButtons[1]._name,
                    userButtons[1]._text,
                    userButtons[1]._x, userButtons[1]._y, { w: userButtons[1]._w, h: userButtons[1]._h, visible: true, color: 0x171c22 },
                ) && !showJoinGame && !showCreateGame
            ) {
                //
            }

            if (
                button(
                    userButtons[2]._name,
                    userButtons[2]._text,
                    userButtons[2]._x, userButtons[2]._y, { w: userButtons[2]._w, h: userButtons[2]._h, visible: true, color: 0x171c22 },
                ) && !showJoinGame && !showCreateGame
            ) {
                //
            }

            //////////////////////////////////////////////////////////////////////////////////

            /*if (
                button("custom", "☰", centerX + 60, centerY + 50, {
                    w: 20,
                    h: 20,
                })
            ) {
                menu = Menu.Games;
            }

            if (button("practice", "🏹 " + L("start_practice"), centerX - 50, centerY + 75, { w: 100, h: 20 })) {
                menu = Menu.Practice;
            }
            if (button("settings", "⚙️", W - 30, 10, { w: 20, h: 20 })) {
                menu = Menu.Settings;
                logScreenView("settings_screen");
            }
            if (button("language", "🈂", W - 30, 35, { w: 20, h: 20 })) {
                menu = Menu.Language;
                logScreenView("language_screen");
            }*/
        } else if (menu === Menu.Settings) {
            label("⚙️ Settings", 10, centerX, 30);
            guiSettingsPanel(centerX, centerY - 20);
            if (
                button("back", "⬅ Back", centerX - 50, centerY + 90, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Main;
            }
        } else if (menu === Menu.Language) {
            label("🈂 Language", 10, centerX, 30);
            guiLanguagesPanel(centerX, centerY - 20);
            if (
                button("back", "⬅ Back", centerX - 50, centerY + 90, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Main;
            }
        } else if (menu === Menu.Dev) {
            label("⚙️ Developer", 10, centerX, 30);
            if (guiDevModePanel(centerX, centerY)) {
                menu = Menu.Main;
            }
            if (
                button("back", "⬅ Back", centerX - 50, centerY + 90, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Main;
            }
        } else if (menu === Menu.Games) {
            label("⚙️ " + L("games_title"), 10, centerX, 30);

            let y = -70;
            let i = 0;
            for (const room of roomsInfo.rooms) {
                if (
                    button(
                        "room" + room.code,
                        `${L("game_room")} #${room.code} (${room.players}/${room.max})`,
                        centerX - 50,
                        centerY + y,
                        {
                            w: 100,
                            h: 20,
                        },
                    )
                ) {
                    if (room.players < room.max) {
                        result = { _command: MenuCommand.JoinGame, _joinByCode: room.code };
                    }
                }
                y += 20;
                ++i;
                if (i > 5) {
                    break;
                }
            }

            y = centerY + 30;
            if (
                button("join_code", L("join_by_code"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                result = { _command: MenuCommand.JoinGame };
            }
            y += 30;

            if (
                button("create", L("create_my_game"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                menu = Menu.CreateGame;
            }
            y += 30;
            if (
                button("back", "⬅ " + L("back"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Main;
            }
        } else if (menu === Menu.Practice) {
            label("🏹 " + L("title_practice"), 10, centerX, 30);

            let y = centerY - 70;
            y += 25;
            if (
                button("npc_level", "NPC: " + L(NPC_LEVELS[newPracticeSettings._newGame._npcLevel]), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                ++newPracticeSettings._newGame._npcLevel;
                if (newPracticeSettings._newGame._npcLevel >= NPC_LEVELS.length) {
                    newPracticeSettings._newGame._npcLevel = 0;
                }
            }
            y += 25;
            const THEME_NAMES = [
                "? " + L("map_random"),
                "🌲 " + L("map_forest"),
                "🌵 " + L("map_desert"),
                "❄ " + L("map_snow"),
            ];
            if (
                button(
                    "map_theme",
                    L("create_game_map") + ": " + THEME_NAMES[newPracticeSettings._newGame._theme],
                    centerX - 50,
                    y,
                    {
                        w: 100,
                        h: 20,
                    },
                )
            ) {
                ++newPracticeSettings._newGame._theme;
                if (newPracticeSettings._newGame._theme > 3) {
                    newPracticeSettings._newGame._theme = 0;
                }
            }
            y += 25;
            y += 25;
            if (
                button("create", "⚔ " + L("practice_start_game"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                result = newPracticeSettings;
            }
            y += 25;
            if (
                button("back", "⬅ " + L("back"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Main;
            }
        } else if (menu === Menu.CreateGame) {
            label("⚙️ " + L("create_game_title"), 10, centerX, 30);
            let y = centerY - 70;
            if (
                button(
                    "visibility",
                    L("create_game_access") +
                    ": " +
                    (newGameSettings._newGame._flags & GameModeFlag.Public
                        ? "👁️ " + L("create_game_public")
                        : "🕵️ " + L("create_game_private")),
                    centerX - 50,
                    y,
                    {
                        w: 100,
                        h: 20,
                    },
                )
            ) {
                newGameSettings._newGame._flags ^= GameModeFlag.Public;
            }
            y += 25;
            if (
                button(
                    "players_limit",
                    L("create_game_max_players") + ": " + newGameSettings._newGame._playersLimit,
                    centerX - 50,
                    y,
                    {
                        w: 100,
                        h: 20,
                    },
                )
            ) {
                const MAX_PLAYERS = 8;
                ++newGameSettings._newGame._playersLimit;
                if (newGameSettings._newGame._playersLimit > MAX_PLAYERS) {
                    newGameSettings._newGame._playersLimit = 2;
                }
            }
            y += 25;
            if (
                button("npc_level", "NPC: " + L(NPC_LEVELS[newGameSettings._newGame._npcLevel]), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                ++newGameSettings._newGame._npcLevel;
                if (newGameSettings._newGame._npcLevel >= NPC_LEVELS.length) {
                    newGameSettings._newGame._npcLevel = 0;
                }
            }
            y += 25;

            const THEME_NAMES = [
                "? " + L("map_random"),
                "🌲 " + L("map_forest"),
                "🌵 " + L("map_desert"),
                "❄ " + L("map_snow"),
            ];
            if (
                button(
                    "map_theme",
                    L("create_game_map") + ": " + THEME_NAMES[newGameSettings._newGame._theme],
                    centerX - 50,
                    y,
                    {
                        w: 100,
                        h: 20,
                    },
                )
            ) {
                ++newGameSettings._newGame._theme;
                if (newGameSettings._newGame._theme > 3) {
                    newGameSettings._newGame._theme = 0;
                }
            }
            y += 25;
            y += 25;
            if (
                button("create", "⚔ " + L("create_game_start_game"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                })
            ) {
                if(clientName.indexOf("#") == -1)
                {
                    setUserName(`${clientName}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
                }
                else
                {
                    setUserName(`${getNameFromName(clientName)}#${datasPlayer.avatar}#${datasPlayer.weapon}#${datasPlayer.weaponSkin}`);
                }
                
                result = newGameSettings;
            }
            y += 25;
            if (
                button("back", "⬅ " + L("back"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                menu = Menu.Games;
            }
        }

        else
        if (menu == Menu.Avatar)
        {
            const buttonSize =
            {
                w: 20,
                h: 20
            };

            const between = 20;

            let x = centerX - between / 2 - buttonSize.w / 2;
            let y = centerY - viewport.h / 2 + buttonSize.h / 2;

            let buttonId = "avatarPrev" + selectMenu.avatar;

            if(selectMenu.avatar > 0)
            {
                if (button(buttonId, "<", x, y, { w: buttonSize.w, h: buttonSize.h, visible: true }))
                {
                    selectMenu.avatar--;
                }
            }

            x = centerX + between / 2 + buttonSize.w / 2;
            buttonId = "avatarNext" + selectMenu.avatar;

            if(selectMenu.avatar < Img.num_avatars - 1)
            {
                if (button(buttonId, ">", x, y, { w: buttonSize.w, h: buttonSize.h, visible: true }))
                {
                    selectMenu.avatar++;
                }
            }

            x = 0.0;
            y = viewport.h / 2;

            if (button("back", "⬅ " + L("back"), x, y, { w: 100, h: 20, }))
            {
                menu = Menu.Main;
            }

            /*let x = centerX - viewport.w / 2 + buttonSize.w / 2;
            let y = centerY - viewport.h / 2 + buttonSize.h / 2;

            for (let i = 0; i < Img.num_avatars; i++) {
                const buttonId = "avatar" + i;

                const icon: string = avatars[i].accessible ? avatars[i].icon : avatars[i].icon + " ⛔";

                if (button(buttonId, icon, x, y, { w: buttonSize.w, h: buttonSize.h, visible: true }) && avatars[i].accessible)
                {
                    datasPlayer.avatar = i;
                    menu = Menu.Main;
                }

                x += buttonSize.w + between;

                if (x >= centerX + viewport.w / 2 - buttonSize.w / 2) {
                    x = centerX - viewport.w / 2 + buttonSize.w / 2;
                    y += buttonSize.h + between;
                }
            }

            y += buttonSize.h + between;

            if (button("back", "⬅ " + L("back"), centerX - 50, y, { w: 100, h: 20, })) {
                menu = Menu.Main;
            }*/
        }

        else
        if (menu == Menu.Weapon)
        {
            const buttonSize =
            {
                w: 50,
                h: 20
            };

            const between = 10;

            let x = between;
            let y = between;

            for (let i = 1 ; i < Img.num_weapons ; i++)
            {
                const buttonId = `weapon${i}`;

                const text: string = GAME_CFG.weapons[i].name;

                if (button(buttonId, text, x, y, { w: buttonSize.w, h: buttonSize.h, visible: true }))
                {
                    datasPlayer.weapon = i;
                    menu = Menu.WeaponSkin;
                }

                y += buttonSize.h + between;

                if(y > viewport.h - buttonSize.h)
                {
                    x += buttonSize.w + between;
                    y = between;
                }
            }

            if (button("back", "⬅ " + L("back"), centerX - 50, y, { w: 100, h: 20, }))
            {
                menu = Menu.Main;
            }
        }

        else
        if (menu == Menu.WeaponSkin)
        {
            const buttonSize =
            {
                w: 50,
                h: 20
            };

            const between = 10;

            let x = between;
            let y = between;

            for (let i = 0 ; i < GAME_CFG.weapons[datasPlayer.weapon].skins.length ; i++)
            {
                //if(GAME_CFG.weapons[datasPlayer.weapon].skins[i].accessible)
                {
                    const skin: any = GAME_CFG.weapons[datasPlayer.weapon].skins[i];
                
                    const buttonId = `weapon_skin${i}`;

                    if (button(buttonId, skin.icon, x, y, { w: buttonSize.w, h: buttonSize.h, visible: true }))
                    {
                        datasPlayer.weaponSkin = i;
                        menu = Menu.Main;
                    }

                    y += buttonSize.h + between;

                    if(y > viewport.h - buttonSize.h)
                    {
                        x += buttonSize.w + between;
                        y = between;
                    }
                }
            }

            if (button("back", "⬅ " + L("back"), centerX - 50, y, { w: 100, h: 20, }))
            {
                menu = Menu.Weapon;
            }
        }

        else
        if(menu == Menu.Keys)
        {
            if(actionToEdit != "")
            {
                label(`Press key for action ${actionToEdit}`, 8, centerX, 8);
            }

            else
            {
                label("Select your action and press the key", 8, centerX, 8);
            }
            
            //console.log("Menu keys");
            const gap: number = 5;

            let y: number = 10;
            
            if (button("left", `Move left : ${displayKey(mapKeys.get("left").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectLeft);

                actionToEdit = "Left";
            }

            y += 20 + gap;

            if (button("right", `Move right : ${displayKey(mapKeys.get("right").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectRight);

                actionToEdit = "Right";
            }

            y += 20 + gap;

            if (button("up", `Move up : ${displayKey(mapKeys.get("up").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectUp);

                actionToEdit = "Up";
            }

            y += 20 + gap;

            if (button("down", `Move down : ${displayKey(mapKeys.get("down").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectDown);

                actionToEdit = "Down";
            }

            y += 20 + gap;

            if (button("jump", `Jump : ${displayKey(mapKeys.get("jump").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectJump);

                actionToEdit = "Jump";
            }

            y += 20 + gap;

            if (button("drop", `Drop : ${displayKey(mapKeys.get("drop").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectDrop);

                actionToEdit = "Drop";
            }

            y += 20 + gap;

            if (button("swap", `Swap : ${displayKey(mapKeys.get("swap").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectSwap);

                actionToEdit = "Swap";
            }

            y += 20 + gap;

            if (button("fast", `Fast : ${displayKey(mapKeys.get("fast").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selecReload);

                document.addEventListener('keydown', selectFast);

                actionToEdit = "Fast";
            }

            y += 20 + gap;

            if (button("reload", `Reload : ${displayKey(mapKeys.get("reload").key)}`, centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);

                document.addEventListener('keydown', selecReload);

                actionToEdit = "Reload";
            }

            y += 20 + gap;

            if (button("back", "⬅ " + L("back"), centerX - 50, y, { w: 100, h: 20, }))
            {
                document.removeEventListener("keydown", selectLeft);
                document.removeEventListener("keydown", selectRight);
                document.removeEventListener("keydown", selectUp);
                document.removeEventListener("keydown", selectDown);
                document.removeEventListener("keydown", selectJump);
                document.removeEventListener("keydown", selectDrop);
                document.removeEventListener("keydown", selectSwap);
                document.removeEventListener("keydown", selectFast);
                document.removeEventListener("keydown", selecReload);

                actionToEdit = "";

                let storageValue = "";

                for (let [action, key] of mapKeys)
                {
                    storageValue += `${action}=${JSON.stringify(key)}\n`;
                }

                localStorage.setItem("bones_keys", storageValue);
                
                menu = Menu.Settings;
            }
        }

        if (IsPokiBuild) {
            label("🏷 " + BuildClientVersion, 8, 2, H - 4, 0);
        }
    }
    ui_finish();
    return result;
};

export default datasPlayer;
