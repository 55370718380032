export let Img = {
    box: 0,
    box_lt: 1,
    box_t: 2,
    box_t1: 3,
    box_l: 4,
    box_r: 5,
    circle_4: 6,
    circle_4_60p: 7,
    circle_4_70p: 8,
    circle_16: 9,

    weapon0: 10,
    weapon1: 11,
    weapon2: 12,
    weapon3: 13,
    weapon4: 14,
    weapon5: 15,
    weapon6: 16,
    weapon7: 17,
    weapon8: 18,
    weapon9: 19,
    weapon10: 20,

    avatar0: 21,
    avatar1: 22,
    avatar2: 23,
    avatar3: 24,
    avatar4: 25,
    avatar5: 26,
    avatar6: 27,
    avatar7: 28,
    avatar8: 29,
    avatar9: 30,
    avatar10: 31,
    avatar11: 32,
    avatar12: 33,
    avatar13: 34,
    avatar14: 35,

    npc0: 36,
    npc1: 37,
    npc2: 38,
    npc3: 39,
    npc4: 40,
    npc5: 41,
    npc6: 42,
    npc7: 43,

    barrel0: 44,
    barrel1: 45,
    barrel2: 46,

    item0: 47,
    item1: 48,
    item2: 49,
    item3: 50,
    item4: 51,
    item5: 52,

    tree0: 53,
    tree1: 54,
    tree2: 55,
    tree3: 56,
    tree4: 57,
    tree5: 58,

    particle_flesh0: 59,
    particle_flesh1: 60,
    particle_shell: 61,

    logo_title: 62,
    logo_subtitle: 63,

    num_avatars: 0,
    num_weapons: 0,
    num_npc: 8,
};// as const;
export type Img = (typeof Img)[keyof typeof Img];
