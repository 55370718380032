import {drawText, drawTextAligned, fnt} from "./font.js";
import {inputPointers, mousePointer, Pointer} from "../utils/input.js";
import {img, currentUser, avatars, items, imgSpotLight, setSkinForCat, skinsByCat, setDatasPlayerWeapon, setDatasPlayerWeaponSkin} from "../assets/gfx.js";
import {Texture, draw, getSubTexture, gl, gl_program, setDrawZ} from "./draw2d.js";
import {getScreenScale} from "../game/camera.js";
import {clamp} from "../utils/math.js";
import {Img} from "../assets/img.js";
import { clientId, clientName, connect, setUserName } from "../net/messaging.js";
import { MenuCommand, menuInventory, menuProfil, showMenuInventory, showMenuProfil, newGameSettings, NPC_LEVELS, newPracticeSettings, menuBallonFight } from "../screens/main.js";
import { getCatSelected, getNameFromName } from "../utils/utils.js";
import { gameMode, getNameByClientId } from "../game/gameState.js";
import { GAME_CFG } from "../game/config.js";
import { logUserEvent } from "../analytics.js";
import { resetGame } from "../game/game.js";
import { modalPopup, setIsModalClosed } from "../modals/index.js";
import { GameModeFlag } from "@iioi/shared/types.js";
import { doc } from "firebase/firestore";
import { datasPlayer } from "../assets/gfx.js";

let y = 8;
export const resetPrinter = () => {
    y = 8;
};
export const termPrint = (text: string, size = 7) => {
    drawText(fnt[0], text, size, 2, y, size, 1);
    y += size + 1;
};

let indexAvatar = -1;
let posCard: any = {};
let posCard2: any = {};
let rarety = "Dust";
let goToGear = "Uzi";

export let creditBonus = 0.5;
let iconSelected = 0;
let iconSelected2: number = -1;
let indexCatSel: number = -1;
let indexSkinSel: number = -1;
let rarety2 = "Dust";
let catSelect = -1;

export const disableCatSel = () =>
{
    indexCatSel = -1;
}

export const disableSkinSel = () =>
{
    indexSkinSel = -1;
}

export const disableIconSel = () =>
{
    iconSelected2 = -1;
}

// https://sol.gfxile.net/imgui/ch03.html

let hotItem = "";
let activeItem = "!";
let pointer: Pointer | null = mousePointer;

interface OpaqueQuad {
    _x: number;
    _y: number;
    _w: number;
    _h: number;
    _color: number;
}

interface TextOp {
    _x: number;
    _y: number;
    _size: number;
    _text: string;
    _alignX?: number;
    _icon?: any;
    _color?: number;
}

export const uiState = {
    _scale: 1,
    _width: 1,
    _height: 1,
    _opaqueQuads: [] as OpaqueQuad[],
    _textOps: [] as TextOp[]
};

const captureInputPointer = () => {
    pointer = mousePointer;
    for (const [, touchPointer] of inputPointers) {
        if (touchPointer._active || touchPointer._upEvent) {
            pointer = touchPointer;
            break;
        }
    }
};

export const ui_begin = () => {
    captureInputPointer();
    hotItem = "";
    setDrawZ(1000);
    uiState._scale = getScreenScale();
    uiState._width = (gl.drawingBufferWidth / uiState._scale) | 0;
    uiState._height = (gl.drawingBufferHeight / uiState._scale) | 0;
};

export const ui_finish = () => {
    if (!pointer._active) {
        activeItem = "";
    } else {
        if (!activeItem) {
            activeItem = "!";
        }
    }
};

export const label = (text: string, size: number, x: number, y: number, alignX = 0.5) => {
    uiState._textOps.push({_x: x, _y: y, _size: size, _text: text, _alignX: alignX});
};

// Check whether current mouse position is within a rectangle
const isRegionHit = (x: number, y: number, w: number, h: number): number => {
    const px = pointer._x / uiState._scale;
    const py = pointer._y / uiState._scale;
    if (px < x || py < y || px >= x + w || py >= y + h) {
        return 0;
    }
    return 1;
};

const getRegionPointerX = (x: number, w: number): number => {
    const px = pointer._x / uiState._scale;
    return (px - x) / w;
};

export let fight: boolean = false;
export const setFight = (value) =>
{
    fight = value;
}

export let startGame: boolean = false;
export const setStartGame = (value) =>
{
    startGame = value;
}

export const button = (
    id: string,
    text: string,
    x: number,
    y: number,
    config?: {w?: number; h?: number; visible?: boolean, color?: number, icon?: any, textColor?: number},
): number => {
    const w = config?.w ?? 64;
    const h = config?.h ?? 16;
    const visible = config?.visible ?? true;
    if (isRegionHit(x, y, w, h)) {
        hotItem = id;
        if (!activeItem && pointer._active) {
            activeItem = id;
        }
    }
    if (visible) {
        let offset = 0;
        let color = 0xffffff;

        if (hotItem === id) {
            if (activeItem === id) {
                // Button is both 'hot' and 'active'
                offset = 1;
            } else {
                // Button is merely 'hot'
                offset = 0;
            }
        } else {
            // button is not hot, but it may be active
            color = config?.color ?? 0xaaaaaa;
        }
        uiState._opaqueQuads.push(
            {
                _x: x + offset,
                _y: y + offset,
                _w: w,
                _h: h,
                _color: color,
            },
            /*{
                _x: x + 2,
                _y: y + 2,
                _w: w,
                _h: h,
                _color: 0,
            },*/
        );
        uiState._textOps.push({
            _x: x + w / 2 + offset,
            _y: y + h / 1.5 + offset,
            _size: 5,
            _text: text,
            _icon: config?.icon,
            _color: config?.textColor,
        });
    }
    // If button is hot and active, but mouse button is not
    // down, the user must have clicked the button.
    if (!pointer._active && hotItem === id && activeItem === id) {
        return 1;
    }

    return 0;
};

export const InitMainBarr = (W: number, H: number) =>
{
    let buttons = [];
    
    if(document.body.clientWidth > 700)
    {
        let x = 0.03 * W;

        for(let i = 0 ; i < 4 ; i++)
        {
            buttons.push({ _x: x, _y: 15, _w: 0.126 * W, _h: 15 });

            x += 0.126 * W;
        }
    }

    else
    {
        let x = 0.0;

        for(let i = 0 ; i < 4 ; i++)
        {
            buttons.push({ _x: x, _y: H - 30, _w: W / 4, _h: 30 });

            x += W / 4;
        }
    }

    buttons[0]._text = "PLAY NOW";
    buttons[1]._text = "INVENTORY";
    buttons[2]._text = "MARKETPLACE";
    buttons[3]._text = "OPTIONS";

    buttons[0]._name = "start";
    buttons[1]._name = "inventory";
    buttons[2]._name = "marketplace";
    buttons[3]._name = "options";

    return buttons;
}

export const InitUserBarr = (W: number, H: number) =>
{
    let buttons = [];
    
    if(document.body.clientWidth > 700)
    {
        let x = 0.633 * W;
        const gap = 0.00726 * W;

        buttons[0] = { _x: x, _y: 15, _w: 0.097 * W, _h: 15, _name: "change_name", _text: getNameFromName(clientName).toUpperCase() };

        x += buttons[0]._w + gap;

        buttons[1] = { _x: x, _y: 15, _w: 0.1493 * W, _h: 12, _name: "points_credit_bonus", _text: "🎖 " + (currentUser ? currentUser.nbPoints.toFixed(2) : "0") + " | ➕ " + (avatars[datasPlayer.avatar].creditBonus ? avatars[datasPlayer.avatar].creditBonus.toFixed(2) : "0.5") };

        x += buttons[1]._w + gap;

        buttons[2] = { _x: x, _y: 15, _w: 0.075 * W, _h: 12, _name: "deads", _text: "💀 " + (currentUser ? currentUser.deads.toString() : "0") };

        //console.log(avatars[datasPlayer.avatar]);
        //console.log(currentUser);
    }

    else
    {
        let x = 0.0527 * W;
        let gap = 0.2023 * W;

        buttons[0] = { _x: x, _y: 15, _w: 0.2375 * W, _h: 15, _name: "username", _text: getNameFromName(clientName).toUpperCase() };

        x += buttons[0]._w + gap;

        buttons[1] = { _x: x, _y: 15, _w: 0.2932 * W, _h: 12, _name: "points_credit_bonus", _text: "🎖 " + (currentUser.nbPoints ? currentUser.nbPoints.toFixed(2) : "0") + " | ➕ " + (avatars[datasPlayer.avatar].creditBonus ? avatars[datasPlayer.avatar].creditBonus.toFixed(2) : "0.5") };

        gap = 0.01759 * W;
        x += buttons[1]._w + gap;

        buttons[2] = { _x: x, _y: 15, _w: 0.1466 * W, _h: 12, _name: "deads", _text: "💀 " + (currentUser.deads ? currentUser.deads.toString() : "0") };
    }

    return buttons;
}

export const uiProgressBar = (
    id: string,
    current: number,
    total: number,
    x: number,
    y: number,
    w: number,
    h: number,
): undefined | number => {
    if (isRegionHit(x, y, w, h)) {
        hotItem = id;
        if (!activeItem && pointer._active) {
            activeItem = id;
        }
    }
    const progress = current / total;
    uiState._opaqueQuads.push(
        {
            _x: x,
            _y: y,
            _w: w * progress,
            _h: h,
            _color: 0xcccccc,
        },
        {
            _x: x + w * progress,
            _y: y,
            _w: w * (1 - progress),
            _h: h,
            _color: 0x333333,
        },
    );

    let r = getRegionPointerX(x, w);
    r = clamp(r, 0, 1);
    r = Math.round(r * total) / total;

    if (!pointer._active && hotItem === id && activeItem === id) {
        return r;
    }

    if (hotItem === id) {
        if (activeItem === id) {
            // Button is both 'hot' and 'active'
        } else {
            // Button is merely 'hot'
        }
        uiState._opaqueQuads.push({
            _x: x,
            _y: y - 1,
            _w: w * r,
            _h: 2,
            _color: 0xffffff,
        });
        return -r - 1;
    } else {
        // button is not hot, but it may be active
    }
};

const renderMenuProfil = () =>
{
    const W = uiState._width;
    
    let positionsIcons = [];

    const quadSize = 0.125 * items[7].tex._w;

    let xIcon: number = document.body.clientWidth > 700 ? 0.633 * W + quadSize / 2 : 0.0527 * W + quadSize / 2;
    let yIcon: number = 30 + quadSize / 2;
    let nbCol = 0;
    let indexIcon = 0;

    avatars.map((a: any, index: number) =>
    {
        if(a.accessible)
        {
            draw(a.texture, xIcon, yIcon, 0, 0.125, 0.125);         //Quads with skins
            draw(items[7].tex, xIcon, yIcon, 0, 0.125, 0.125);

            if (isRegionHit(xIcon - quadSize / 2, yIcon - quadSize / 2, quadSize, quadSize) && pointer._downEvent)
            {
                if(indexAvatar == -1)
                {
                    indexAvatar = index;
                }
                else
                {
                    indexAvatar = -1;
                }
                
                rarety = a.rarety;
                goToGear = a.gotoGear;
                creditBonus = a.creditBonus;
                iconSelected = indexIcon;
            }

            positionsIcons.push({ x: xIcon, y: yIcon });

            xIcon += quadSize;
            nbCol++;
            
            if(nbCol > 4)
            {
                yIcon += quadSize;
                xIcon = document.body.clientWidth > 700 ? 0.633 * W + quadSize / 2 : 0.0527 * W + quadSize / 2;
                nbCol = 0;
            }

            indexIcon++;
        }
    });

    if(indexAvatar != -1)
    {
        posCard = { x: positionsIcons[iconSelected].x - quadSize / 2 + items[12].scale * items[12].tex._w / 2, y: positionsIcons[iconSelected].y + items[12].scale * items[12].tex._h / 2 };
        
        //console.log(avatars[indexAvatar]);
        setDrawZ(10000);

        let indexRarety = 8;
        let indexRarety2 = 13;

        if(rarety == "Dust")
        {
            indexRarety = 8;
            indexRarety2 = 13;
        }

        else
        if(rarety == "Marrow")
        {
            indexRarety = 9;
            indexRarety2 = 14;
        }

        else
        if(rarety == "Bane")
        {
            indexRarety = 10;
            indexRarety2 = 15;
        }

        else
        if(rarety == "Mythic")
        {
            indexRarety = 11;
            indexRarety2 = 16;
        }

        const xRarety: number = 0.8 * items[indexRarety].scale * items[indexRarety].tex._w + posCard.x - items[12].scale * items[12].tex._w / 2;
        const yRarety: number = 0.0445 * items[indexRarety].scale * items[indexRarety].tex._h + posCard.y - items[12].scale * items[12].tex._h / 2 + 1.5 * items[indexRarety2].scale * items[indexRarety2].tex._h;

        draw(items[indexRarety2].tex, xRarety, yRarety, 0, items[indexRarety2].scale, items[indexRarety2].scale);       //Rarety

        let xEquip: number = posCard.x;
        let yEquip: number = posCard.y + items[12].scale * items[12].tex._h / 2;

        const wEquipe: number = items[17].scale * items[17].tex._w;
        const hEquipe: number = items[17].scale * items[17].tex._h;

        if (isRegionHit(xEquip - wEquipe / 2, yEquip - hEquipe / 2, wEquipe, hEquipe) && pointer._downEvent)
        {
            datasPlayer.avatar = indexAvatar;

            xEquip += 1;
            yEquip += 1;

            showMenuProfil(false);
            indexAvatar = -1;

            for(let i = 0 ; i < GAME_CFG.weapons.length ; i++)
            {
                //console.log(GAME_CFG.weapons[i].name.toLowerCase(), goToGear.toLowerCase(), i)
                
                if(GAME_CFG.weapons[i].name.toLowerCase() == goToGear.toLowerCase())
                {
                    datasPlayer.weapon = i;
                    
                    /*for(let j = 0 ; j < GAME_CFG.weapons[i].skins.length ; j++)
                    {
                        if(GAME_CFG.weapons[i].skins[j].accessible)
                        {
                            datasPlayer.weaponSkin = j;
                            break;
                        }
                    }*/

                    datasPlayer.weaponSkin = skinsByCat[datasPlayer.weapon];
                    
                    break;
                }
            }

            //console.log(datasPlayer.weapon);
        }

        draw(items[17].tex, xEquip, yEquip, 0, items[17].scale, items[17].scale);          //BUTTON EQUIP

        ////////////////////////////////////////////////////////////

        const xGoToGearValue: number = posCard.x - 0.25 * items[12].scale * items[12].tex._w;       //Go to gear (Value)
        const yGoToGearValue: number = posCard.y + 0.4 * items[12].scale * items[12].tex._h;

        uiState._textOps.push({
            _x: xGoToGearValue,
            _y: yGoToGearValue,
            _size: 4,
            _text: goToGear,
            _icon: null,
            _color: 0xffffff
        });

        ////////////////////////////////////////////////////////////

        const xGoToGear: number = posCard.x - 0.25 * items[12].scale * items[12].tex._w;
        const yGoToGear: number = posCard.y + 0.3 * items[12].scale * items[12].tex._h;

        draw(items[18].tex, xGoToGear, yGoToGear, 0, items[18].scale, items[18].scale);       //Go to gear (Text)

        ////////////////////////////////////////////////////////////

        const xCreditBonusValue: number = posCard.x + 0.25 * items[12].scale * items[12].tex._w;       //Credit bonus (Value)
        const yCreditBonusValue: number = posCard.y + 0.4 * items[12].scale * items[12].tex._h;

        uiState._textOps.push({
            _x: xCreditBonusValue,
            _y: yCreditBonusValue,
            _size: 4,
            _text: creditBonus.toFixed(2),
            _icon: null,
            _color: 0xffffff
        });

        ////////////////////////////////////////////////////////////

        const xCreditBonus: number = posCard.x + 0.25 * items[12].scale * items[12].tex._w;
        const yCreditBonus: number = posCard.y + 0.3 * items[12].scale * items[12].tex._h;

        draw(items[19].tex, xCreditBonus, yCreditBonus, 0, items[19].scale, items[19].scale);       //Credit bonus (Text)

        ////////////////////////////////////////////////////////////

        draw(avatars[indexAvatar].texture, posCard.x, posCard.y, 0, 2 * avatars[indexAvatar].scale, 2 * avatars[indexAvatar].scale);       //Skin
        draw(items[indexRarety].tex, posCard.x, posCard.y, 0, items[indexRarety].scale, items[indexRarety].scale);                      //Background

        ////////////////////////////////////////////////////////////

        posCard.y += (items[12].tex._h * items[12].scale - items[8].tex._h * items[8].scale) / 2;

        draw(items[12].tex, posCard.x, posCard.y, 0, items[12].scale, items[12].scale);             //Background card

        posCard.y -= (items[12].tex._h * items[12].scale - items[8].tex._h * items[8].scale) / 2;
    }
}

const renderMenuInventory = () =>
{
    const W = uiState._width;
    const H = uiState._height;

    let nbIcons: number = 0;

    for(const weaponCat of GAME_CFG.weapons)
    {
        nbIcons += weaponCat.textures.length;
    }

    const nbCol = 10;

    let nbRows: number = nbIcons / nbCol;

    if(nbRows != Math.floor(nbRows))
    {
        nbRows = Math.floor(nbRows) + 1;
    }
    
    const wMenu: number = document.body.clientWidth > 700 ? 0.65 * W : 0.899 * W;
    const wIcon: number = 0.079 * wMenu;
    const gap: number = 0.01265 * wMenu;
    let hMenu: number = document.body.clientWidth > 700 ? 0.097 * H + nbRows * wIcon + (nbRows - 1) * gap + 0.0242 * H : 0.0752 * H + nbRows * wIcon + (nbRows - 1) * gap + 0.0208 * H;
    const xMenu: number = document.body.clientWidth > 700 ? 0.032 * W : (W - wMenu) / 2;
    const yMenu: number = document.body.clientWidth > 700 ? 0.137 * H : H - 0.1024 * H - hMenu;

    if(yMenu + hMenu > H)
    {
        hMenu = H - yMenu - 50;
    }

    let xIcon: number = xMenu + 0.0445 * wMenu + wIcon / 2;
    let yIcon: number = yMenu + 0.2479 * hMenu;

    let indexIcon: number = 0;

    let indexCat: number = 0;

    let positionsIcons = [];

    let indexRarety: number;
    let indexRarety2: number;

    for(let i = 0 ; i < GAME_CFG.weapons.length ; i++)
    {
        for(let j = 0 ; j < GAME_CFG.weapons[i].skins.length ; j++)
        {
            if((catSelect == -1 || catSelect == i) && yIcon + wIcon < yMenu + hMenu && GAME_CFG.weapons[i].skins[j].accessible)
            {
                //if(i == datasPlayer.weapon && j == datasPlayer.weaponSkin)
                if(j == skinsByCat[i])
                {
                    const wOK = items[20].tex._w * items[20].scale;
                    
                    draw(items[20].tex, xIcon + wIcon / 2 - wOK / 2, yIcon + wIcon / 2 - wOK / 2, 0, items[20].scale, items[20].scale, 0.5);
                }
                
                //draw(GAME_CFG.weapons[i].textures[j], xIcon, yIcon, 0, 0.8 * wIcon / GAME_CFG.weapons[i].textures[j]._w, 0.8 * wIcon / GAME_CFG.weapons[i].textures[j]._h, 1.0, GAME_CFG.weapons[i].skins[j].accessible && goToGear == GAME_CFG.weapons[i].skins[j].category ? 0xffffff : 0x444444);
                draw(GAME_CFG.weapons[i].textures[j], xIcon, yIcon, 0, 0.8 * wIcon / GAME_CFG.weapons[i].textures[j]._w, 0.8 * wIcon / GAME_CFG.weapons[i].textures[j]._h, 1.0, 0xffffff);
                draw(items[7].tex, xIcon, yIcon, 0, wIcon / items[7].tex._w, wIcon / items[7].tex._h);
            
                //if (isRegionHit(xIcon - wIcon / 2, yIcon - wIcon / 2, wIcon, wIcon) && pointer._downEvent && weaponCat.skins[i].accessible && goToGear == weaponCat.skins[i].category)
                if (isRegionHit(xIcon - wIcon / 2, yIcon - wIcon / 2, wIcon, wIcon) && pointer._downEvent)// && GAME_CFG.weapons[i].skins[j].accessible)
                {
                    if(iconSelected2 == -1)
                    {
                        indexCatSel = indexCat;
                        indexSkinSel = j;
                        iconSelected2 = indexIcon;
                        rarety2 = GAME_CFG.weapons[indexCat].skins[j].rarity;
                    }

                    else
                    {
                        indexCatSel = -1;
                        indexSkinSel = -1;
                        iconSelected2 = -1;
                        rarety2 = "Dust";
                    }
                }

                positionsIcons.push({ x: xIcon, y: yIcon });

                xIcon += wIcon + gap;

                if((indexIcon + 1) % nbCol == 0)
                {
                    xIcon = xMenu + 0.0445 * wMenu + wIcon / 2;
                    yIcon += wIcon + gap;
                }

                indexIcon++;
            }
        }

        indexCat++;
    }

    uiState._textOps.push({
        _x: xMenu + wMenu / 2,
        _y: yMenu + 10,
        _size: 6,
        _text: "WEAPONS",
        _icon: null,
        _color: 0xffffff
    });

    const wIconCat = items[21].tex._w * items[21].scale;
    let xIconCat = xMenu + wMenu / 2 - 5 * wIconCat - 4 * 2 * gap;
    const yIconCat = yMenu + 12 + wIconCat / 2;

    uiState._textOps.push({
        _x: xIconCat,
        _y: yIconCat + 2,
        _size: 6,
        _text: "ALL",
        _icon: null,
        _color: catSelect == -1 ? 0xffffff : 0x808080
    });

    if (isRegionHit(xIconCat - wIconCat / 2, yIconCat - wIconCat / 2, wIconCat, wIconCat) && pointer._downEvent)
    {
        catSelect = -1;
    }

    xIconCat += wIconCat + 2 * gap;

    for(let indexIconCat = 21 ; indexIconCat < 31 ; indexIconCat++)
    {
        draw(items[indexIconCat].tex, xIconCat, yIconCat, 0, items[indexIconCat].scale, items[indexIconCat].scale, 1.0, catSelect != getCatSelected(indexIconCat) ? 0x808080 : 0xffffff);

        if (isRegionHit(xIconCat - wIconCat / 2, yIconCat - wIconCat / 2, wIconCat, wIconCat) && pointer._downEvent)
        {
            catSelect = getCatSelected(indexIconCat);
        }

        xIconCat += wIconCat + 2 * gap;
    }

    draw(img[Img.box_lt], xMenu, yMenu, 0, wMenu, hMenu, 1, 0x171c22);

    if(indexCatSel != -1 && indexSkinSel != -1 && iconSelected2 != -1)
    {
        setDrawZ(10000);

        posCard2 = { x: positionsIcons[iconSelected2].x + wIcon / 2 + items[12].scale * items[12].tex._w / 2, y: positionsIcons[iconSelected2].y + wIcon / 2 - items[12].scale * items[12].tex._h / 2 };

        if(positionsIcons[iconSelected2].y <= H / 2)
        {
            posCard2.y = positionsIcons[iconSelected2].y + items[12].scale * items[12].tex._h / 2;
        }

        ////////////////////////////////////////////////////////////

        if(rarety2 == "Dust")
        {
            indexRarety = 8;
            indexRarety2 = 13;
        }

        else
        if(rarety2 == "Marrow")
        {
            indexRarety = 9;
            indexRarety2 = 14;
        }

        else
        if(rarety2 == "Bane")
        {
            indexRarety = 10;
            indexRarety2 = 15;
        }

        else
        if(rarety2 == "Mythic")
        {
            indexRarety = 11;
            indexRarety2 = 16;
        }

        const xBG: number = posCard2.x;
        const yBG: number = posCard2.y - items[12].scale * items[12].tex._h / 2 + items[indexRarety].scale * items[indexRarety].tex._h / 2;
        
        draw(GAME_CFG.weapons[indexCatSel].textures[indexSkinSel], xBG, yBG, -10 * Math.PI / 180, 2 * GAME_CFG.weapons[indexCatSel].skins[indexSkinSel].scale, 2 * GAME_CFG.weapons[indexCatSel].skins[indexSkinSel].scale);       //Skin

        const xRarety: number = 0.8 * items[indexRarety].scale * items[indexRarety].tex._w + posCard2.x - items[12].scale * items[12].tex._w / 2;
        const yRarety: number = 0.0445 * items[indexRarety].scale * items[indexRarety].tex._h + yBG - items[12].scale * items[12].tex._h / 2 + 1.5 * items[indexRarety2].scale * items[indexRarety2].tex._h;
        
        draw(items[indexRarety2].tex, xRarety, yRarety, 0, items[indexRarety2].scale, items[indexRarety2].scale);       //Rarety

        const wEquipe: number = items[17].scale * items[17].tex._w;
        const hEquipe: number = items[17].scale * items[17].tex._h;

        let xEquip: number = posCard2.x;
        let yEquip: number = posCard2.y + items[12].scale * items[12].tex._h / 2 - 0.6 * hEquipe;

        if (isRegionHit(xEquip - wEquipe / 2, yEquip - hEquipe / 2, wEquipe, hEquipe) && pointer._downEvent)
        {
            /*datasPlayer.weapon = indexCatSel;
            datasPlayer.weaponSkin = indexSkinSel;*/
            setSkinForCat(indexCatSel, indexSkinSel);

            xEquip += 1;
            yEquip += 1;

            iconSelected2 = -1;

            showMenuInventory(false);
        }

        draw(items[17].tex, xEquip, yEquip, 0, items[17].scale, items[17].scale);          //BUTTON EQUIP
        
        draw(items[indexRarety].tex, xBG, yBG, 0, items[indexRarety].scale, items[indexRarety].scale);                      //Background

        ////////////////////////////////////////////////////////////
        
        draw(items[12].tex, posCard2.x, posCard2.y, 0.0, items[12].scale, items[12].scale, 1.0, 0xffffff);          //Card
    }
}

export let showJoinGame: boolean = false;
export const setShowJoinGame = (value: boolean) => { showJoinGame = value };

export let showCreateGame: boolean = false;
export const setShowCreateGame = (value: boolean) => { showCreateGame = value };

/*export const renderJoinGame = () =>
{
    const W = uiState._width;
    const H = uiState._height;

    const wModal: number = 0.358 * W;
    const hModal: number = 0.2112 * H;
    const xModal: number = (W - wModal) / 2;
    const yModal: number = (H - hModal) / 2;

    const xText: number = document.body.clientWidth > 700 ? 40 : 30;

    const xTextarea: number = xModal + 0.057 * wModal;
    const yTextarea: number = yModal + 0.616 * hModal;
    const wTextarea: number = 0.637 * wModal;
    const hTextarea: number = 0.24812 * hModal;

    draw(img[Img.box_lt], xTextarea, yTextarea, 0, wTextarea, hTextarea, 1, 0x1a212a);

    draw(img[Img.box_lt], xModal, yModal, 0, wModal, hModal, 1, 0x171c22);

    const xTitle: number = xModal + wModal / 2;
    const yTitle: number = yModal + 0.0103 * hModal + 20;

    const xCross: number = xModal + 0.8943 * wModal;
    const yCross: number = yTitle;

    uiState._textOps.push({
        _x: xTitle,
        _y: yTitle,
        _size: 6,
        _text: "JOIN GAME",
        _icon: null,
        _color: 0xffffff
    });

    uiState._textOps.push({
        _x: xCross,
        _y: yCross,
        _size: 6,
        _text: "X",
        _icon: null,
        _color: 0xffffff
    });

    uiState._textOps.push({
        _x: xTextarea + xText,
        _y: yTextarea + hTextarea / 2 + 2,
        _size: 6,
        _text: codeJoin,
        _icon: null,
        _color: 0xffffff
    });

    /////////////////////////////////////////////////////////////////////////////////////////////

    if(isRegionHit(xCross - 10, yCross - 10, 20, 20) && pointer._downEvent)
    {
        showJoinGame = false;
        codeJoin="";
    }
    
    document.addEventListener('keydown', handleKeyDown);
}*/

const renderMenuBallonFight = () =>
{
    const W = uiState._width;
    const H = uiState._height;

    const wDropDown: number = document.body.clientWidth > 700 ? 0.26037 * W : 0.899 * W;
    const hDropDown: number = document.body.clientWidth > 700 ? 0.28277 * H : 0.264 * H;
    const xDropDown: number = document.body.clientWidth > 700 ? 0.033 * W : (W - wDropDown) / 2;
    const yDropDown: number = document.body.clientWidth > 700 ? 0.1325 * H : 0.6348 * H;

    const xTitle: number = xDropDown + wDropDown / 2;
    const yTitle: number = yDropDown + 0.0103 * hDropDown + 10;

    uiState._textOps.push({
        _x: xTitle,
        _y: yTitle,
        _size: 6,
        _text: "BALLON FIGHT",
        _icon: null,
        _color: 0xffffff
    });

    const wButton: number = 0.88 * wDropDown;
    const hButton: number = 0.1813 * hDropDown;
    let yButton: number = yDropDown + 0.27979 * hDropDown;
    const xButton: number = xDropDown + (wDropDown - wButton) / 2;
    const gap: number = 0.0362 * hDropDown;

    const texts: string[] = ["FIGHT", "JOIN BY CODE", "CREATE MY GAME"];

    let yButtons: number[] = [];

    for(let i = 0 ; i < 3 ; i++)
    {
        yButtons.push(yButton);

        yButton += hButton + gap;
    }

    for(let i = 0 ; i < 3 ; i++)
    {
        uiState._textOps.push({
            _x: xButton + wButton / 2,
            _y: yButtons[i] + hButton / 1.5,
            _size: 6,
            _text: texts[i],
            _icon: null,
            _color: 0xffffff
        });
        
        draw(img[Img.box_lt], xButton, yButtons[i], 0, wButton, hButton, 1, 0x1a212a);
    }

    draw(img[Img.box_lt], xDropDown, yDropDown, 0, wDropDown, hDropDown, 1, 0x171c22);

    /////////////////////////////////////////////////////////////////////////////////////////////

    if(isRegionHit(xButton, yButtons[0], wButton, hButton) && pointer._downEvent && !showJoinGame)
    {
        fight = true;
    }

    if(isRegionHit(xButton, yButtons[1], wButton, hButton) && pointer._downEvent)
    {
        showJoinGame = true;
    }

    if(isRegionHit(xButton, yButtons[2], wButton, hButton) && pointer._downEvent)
    {
        showCreateGame = true;
    }
}

const renderCreateGame = () =>
{
    const W = uiState._width;
    const H = uiState._height;

    const wBG: number = document.body.clientWidth > 700 ? 0.35587 * W : 0.8937 * W;
    const hBG: number = document.body.clientWidth > 700 ? 0.50529 * H : 0.4837 * H;
    const xBG: number = (W - wBG) / 2;
    const yBG: number = (H - hBG) / 2;

    const wCross: number = 0.052 * wBG;
    const xCross: number = xBG + 0.8936 * wBG;
    const yCross: number = yBG + 0.0653 * hBG;

    const hTitle: number = 0.0287 * hBG;
    const xTitle: number = xBG + wBG / 2;
    const yTitle: number = yBG + 0.08355 * hBG;

    uiState._textOps.push({
        _x: xCross,
        _y: yCross + wCross / 2,
        _size: 6,
        _text: "X",
        _icon: null,
        _color: 0xffffff
    });

    draw(items[7].tex, xCross, yCross, 0, wCross / items[7].tex._w, wCross / items[7].tex._h);

    ///////////

    uiState._textOps.push({
        _x: xTitle,
        _y: yTitle + hTitle / 2,
        _size: 6,
        _text: "CREATE GAME ROOM",
        _icon: null,
        _color: 0xffffff
    });

    ///////////

    const gap: number = 0.0337 * hBG;
    const wButton: number = 0.8818 * wBG;
    const hButton: number = 0.1015 * hBG;
    const xButton: number = xBG + (wBG - wButton) / 2;
    const yButton: number[] = //yBG + 0.2552 * hBG;
    [
        yBG + 0.2552 * hBG,
        yBG + 0.2552 * hBG + hButton + gap,
        yBG + 0.2552 * hBG + 2 * hButton +  2 * gap,
        yBG + 0.2552 * hBG + 3 * hButton +  3 * gap,
    ];

    const THEME_NAMES = [
        "random",
        "forest",
        "desert",
        "snow"
    ];

    const texts: string[] =
    [
        "Access " + (newGameSettings._newGame._flags & GameModeFlag.Public ? "public" : "private"),
        "Max players " + newGameSettings._newGame._playersLimit,
        "NPC " + NPC_LEVELS[newGameSettings._newGame._npcLevel],
        "Map " + THEME_NAMES[newGameSettings._newGame._theme]
    ];

    for(let i = 0 ; i < 4 ; i++)
    {
        uiState._textOps.push({
            _x: xButton + wButton / 2,
            _y: yButton[i] + hButton/2,
            _size: 5,
            _text: texts[i],
            _icon: null,
            _color: 0xffffff
        });
        
        draw(img[Img.box_lt], xButton, yButton[i], 0, wButton, hButton, 1, 0x1a212a);
    }

    const xButtonStart: number = xBG + 0.3435 * wBG;
    const yButtonStart: number = yBG + 0.83073 * hBG;
    const wButtonStart: number = 0.3057 * wBG;
    const hButtonStart: number = hButton;

    uiState._textOps.push({
        _x: xButtonStart + wButtonStart / 2,
        _y: yButtonStart + 0.65 * hButtonStart,
        _size: 5,
        _text: "START GAME",
        _icon: null,
        _color: 0x11151b
    });

    draw(img[Img.box_lt], xButtonStart, yButtonStart, 0, wButtonStart, hButtonStart, 1, 0xf5f7fa);

    draw(img[Img.box_lt], xBG, yBG, 0, wBG, hBG, 1, 0x171c22);

    ////////////////////////////////////////////

    if(isRegionHit(xCross - wCross / 2, yCross - wCross / 2, wCross, wCross) && pointer._downEvent)
    {
        showCreateGame = false;
    }

    //////////////////////

    if(isRegionHit(xButtonStart, yButtonStart, wButtonStart, hButtonStart) && pointer._downEvent)
    {
        startGame = true;
    }

    //////////////////////

    if(isRegionHit(xButton, yButton[0], wButton, hButton) && pointer._downEvent)
    {
        newGameSettings._newGame._flags ^= GameModeFlag.Public;
    }

    else
    if(isRegionHit(xButton, yButton[1], wButton, hButton) && pointer._downEvent)
    {
        const MAX_PLAYERS = 8;

        ++newGameSettings._newGame._playersLimit;

        if (newGameSettings._newGame._playersLimit > MAX_PLAYERS)
        {
            newGameSettings._newGame._playersLimit = 2;
        }
    }

    else
    if(isRegionHit(xButton, yButton[2], wButton, hButton) && pointer._downEvent)
    {
        ++newGameSettings._newGame._npcLevel;

        if (newGameSettings._newGame._npcLevel >= NPC_LEVELS.length)
        {
            newGameSettings._newGame._npcLevel = 0;
        }
    }

    else
    if(isRegionHit(xButton, yButton[3], wButton, hButton) && pointer._downEvent)
    {
        ++newGameSettings._newGame._theme;

        if (newGameSettings._newGame._theme > 3)
        {
            newGameSettings._newGame._theme = 0;
        }
    }
}

export const ui_renderOpaque = () => {
    /*for (const i of uiState._imgOps) {
        draw(i.texture, i._x, i._y, 0, i._w, i._h, 1, i.color ?? 0xffffff);
    }*/
    
    for (const q of uiState._opaqueQuads) {
        draw(img[Img.box_lt], q._x, q._y, 0, q._w, q._h, 1, q._color);
    }

    if(menuProfil)
    {
        renderMenuProfil();
    }

    if(menuInventory)
    {
        renderMenuInventory();
    }

    if(menuBallonFight)
    {
        renderMenuBallonFight();
    }

    if(showCreateGame)
    {
        renderCreateGame();
    }
};

export const ui_renderNormal = () => {
    for (const t of uiState._textOps) {
        drawTextAligned(fnt[0], t._text, t._size, t._x, t._y, t._color, t._alignX, 1.0, t._icon);
    }
};

export const ui_renderComplete = () => {
    uiState._opaqueQuads.length = 0;
    uiState._textOps.length = 0;
    //uiState._imgOps.length = 0;
};
