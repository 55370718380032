import { mapKeys } from "../game/controls.js";
import { modalMessagePopup } from "../modals/index.js";

export const selectLeft = (event) =>
{
    //console.log(event);
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("left", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectRight = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("right", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectUp = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("up", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectDown = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("down", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectJump = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("jump", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectDrop = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("drop", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectSwap = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("swap", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selectFast = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("fast", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const selecReload = (event) =>
{
    let alreadyUsed: boolean = false;

    for (let keyCode of mapKeys.values())
    {
        if(keyCode.code == event.keyCode)
        {
            alreadyUsed = true;
            break;
        }
    }

    if(!alreadyUsed)
    {
        mapKeys.set("reload", {code: event.keyCode, key: event.key});
    }

    else
    {
        modalMessagePopup({title: "Oups", desc: "This key is already used"});
    }
}

export const displayKey = (value: string): string =>
{
    let res = value;

    if(value.length == 1)
    {
        res = value.toUpperCase();
    }

    if(value == " ")
    {
        res = "Space";
    }

    return res;
}