import { createTexture, getSubTexture, Texture, uploadTexture } from "../graphics/draw2d.js";
import { GL } from "../graphics/gl.js";
import { loadArrayBuffer, loadImage, loadJSON } from "../utils/loaders.js";
import { GAME_CFG } from "../game/config.js";
import { Img } from "./img.js";
//import { avatarsAccessibles } from "../utils/utils.js";
import { apiUrl } from "../../../../config.js";
import { WeaponConfig } from "../data/config.js";
import { setUserName } from "../net/messaging.js";
import { modalMessagePopup, modalPopup, modalScorePopup } from "../modals/index.js";
import { loadKeysSettings } from "../utils/utils.js";

export const EMOJI: Record<number, string> = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "🔪",
    "🪓",
    "🔫",
    "🖊️",
    "✏️️",
    "🪥",
    "⛏",
    "🔌",
    "🧵",
    "🧣",
    "🔥",
    "🔥",
    "🔥",
    "🔥",
    "🔥",
    "🛢",
    "📦",
    "🪦",
    "❤️",
    "💊",
    "🪙",
    "💎",
    "🛡",
    "🧱",
    "🌳",
    "🌲",
    "🌵",
    "🌴",
    "🎄",
    "⛄",
    "🥓",
    "🦴",
];

type Item =
{
    tex: Texture,
    scale: number
}

export const img: Texture[] = [];
export const avatars = [];
export const items: Item[] = [];
//export const weapons = [];
export let imgSpotLight: Texture | undefined;
export let logo: Texture;
//export let userId: string = "";
export let currentUser: any = {};
export const setCurrentUser = (cUser: any) => { currentUser = cUser };

export let datasPlayer =
{
    avatar: 0,
    weapon: 1,//0,
    weaponSkin: 0
};

export let skinsByCat: any[] = [-1];
export const setSkinForCat = (indexCat: number, indexSkin: number) =>
{
    skinsByCat[indexCat] = indexSkin;
}

export const setDatasPlayerAvatar = (avatar) =>
{
    datasPlayer.avatar = avatar;
}

export const setDatasPlayerWeapon = (weapon) =>
{
    datasPlayer.weapon = weapon;
}

export const setDatasPlayerWeaponSkin = (weaponSkin) =>
{
    datasPlayer.weaponSkin = weaponSkin;
}

export const loadMainAtlas = async (): Promise<void> => {

    loadKeysSettings();

    const [image, buffer] = await Promise.all([loadImage("main.png"), loadArrayBuffer("main.dat")]);
    const texture = createTexture(image.width, image.height);
    uploadTexture(texture, image);

    //const imageNames: string[] = getPNG();

    const i32 = new Int32Array(buffer);
    //const f32 = new Float32Array(buffer);
    let ptr = 0;
    const f32 = await loadJSON<Omit<Avatar, "icon">[]>('indices.json');
    //const subImagesCount = i32[ptr++] | 0;
    /*const verticesCount = i32[ptr++] | 0;
    const indicesCount = i32[ptr++] | 0;*/

    let res = await fetch(`${apiUrl}/api/users/get`, { credentials: "include" });
    currentUser = await res.json();

    //console.log(currentUser);

    //const date = new Date("2024-06-21T00:00:00");
    //const timestamp = date.getTime() / 1000;
    /*console.log(timestamp);

    console.log(currentUser.created._seconds, currentUser.isAffilated);*/

    if(!currentUser)
    {
        document.body.style.display = "none";
    }

    setUserName(currentUser ? currentUser.username : "Guest " + ((Math.random() * 1000) | 0));
    
    res = await fetch(`${apiUrl}/api/vertex_datas/get_all`);//await loadJSON<Float32Array>('vertex_datas.json');//new Float32Array(buffer, (ptr + subImagesCount * 10) * 4, verticesCount);
    //const vertexData = await loadJSON<Float32Array>('vertex_datas.json');
    const vertexData = await res.json();

    const indexData = await loadJSON<Uint16Array>('index_datas.json');//new Uint16Array(buffer, (ptr + subImagesCount * 10 + verticesCount) * 4, indicesCount);
    //console.log(vertexData);
    //console.log(indexData);
    //console.log(Img);
    for (let i = 0; i < f32.length; ++i) {
        const subImage = getSubTexture(
            texture,
            /*f32[ptr] | 0,
            f32[ptr+1] | 0,
            f32[ptr+2] | 0,
            f32[ptr+3] | 0,
            f32[ptr+4],
            f32[ptr+5],*/
            f32[i].x | 0,
            f32[i].y | 0,
            f32[i].w | 0,
            f32[i].h | 0,
            f32[i].ax,
            f32[i].ay
        );
        /*subImage._index0 = f32[ptr+6] | 0;
        subImage._triangles = f32[ptr+7] | 0;
        subImage._vertex0 = f32[ptr+8] | 0;
        subImage._vertexCount = f32[ptr+9] | 0;*/
        subImage._index0 = f32[i].index0 | 0;
        subImage._triangles = f32[i].triangles | 0;
        subImage._vertex0 = f32[i].vertex0 | 0;
        subImage._vertexCount = f32[i].vertexCount | 0;
        subImage._vertices = vertexData;
        subImage._indices = indexData;
        img[i] = subImage;

        /*console.log("x: " + f32[ptr]);
        console.log("y: " + f32[ptr+1]);
        console.log("w: " + f32[ptr+2]);
        console.log("h: " + f32[ptr+3]);
        console.log("ax: " + f32[ptr+4]);
        console.log("ay: " + f32[ptr+5]);
        console.log("_index0: " + f32[ptr+6]);
        console.log("_triangles: " + f32[ptr+7]);
        console.log("_vertex0: " + f32[ptr+8]);
        console.log("_vertexCount: " + f32[ptr+9]);*/

        ptr += 10;
    }

    type Avatar =
    {
        x: number,
        y: number,
        w: number,
        h: number,
        ax: number,
        ay: number,
        index0: number,
        triangles: number,
        vertex0: number,
        vertexCount: number,
        icon: string,
        scale: number,
        src: string
    }

    //const avatarsDatas = await loadJSON<Avatar[]>('avatars.json');
    res = await fetch(`${apiUrl}/api/avatar/get_all`, { credentials: "include" });
    const avatarsDatas = await res.json();

    Img.num_avatars = avatarsDatas.length;

    for (let i = 0; i < avatarsDatas.length; i++) {
        
        const [imgage] = await Promise.all([loadImage(`${avatarsDatas[i].src}.png`)]);
        const tex = createTexture(imgage.width, imgage.height);
        uploadTexture(tex, imgage);
        //console.log(`${avatarsDatas[i].src}.png`);
        
        const subImage = getSubTexture(
            tex,
            avatarsDatas[i].x | 0,
            avatarsDatas[i].y | 0,
            avatarsDatas[i].w | 0,
            avatarsDatas[i].h | 0,
            avatarsDatas[i].ax,
            avatarsDatas[i].ay
        );

        subImage._index0 = avatarsDatas[i].index0 | 0;
        subImage._triangles = avatarsDatas[i].triangles | 0;
        subImage._vertex0 = avatarsDatas[i].vertex0 | 0;
        subImage._vertexCount = avatarsDatas[i].vertexCount | 0;
        subImage._vertices = vertexData;
        subImage._indices = indexData;

        const avatar =
        {
            texture: subImage,
            name: "",
            bonus: 0.0,
            icon: avatarsDatas[i].icon,
            scale: avatarsDatas[i].scale,
            free: avatarsDatas[i].free,
            id: avatarsDatas[i].id,
            accessible: avatarsDatas[i].accessible,
            creditBonus: avatarsDatas[i].creditBonus,
            rarety: avatarsDatas[i].rarety,
            gotoGear: avatarsDatas[i].gotoGear,
        }

        avatars[i] = avatar;
    }

    //await avatarsAccessibles();

    //console.log(avatars);

    //const weapons: any[] = await loadJSON('weapons.json');
    /*res = await fetch("/w");//await fetch("http://localhost:3000/api/weapons/get_all");
    const weapons = await res.json();*/

    //console.log(weapons);

    res = await fetch(`${apiUrl}/api/weapons/get_all`, { credentials: "include" });
    const weaponsData = await res.json();

    //console.log(weaponsData);

    Img.num_weapons = weaponsData.length;

    GAME_CFG.weapons = [];

    //console.log(weaponsData);

    for (let i = 0; i < weaponsData.length; i++)
    {
        /*const [imgage] = await Promise.all([loadImage(`${weapons[i].src}.png`)]);
        const tex = createTexture(imgage.width, imgage.height);
        uploadTexture(tex, imgage);
        
        const subImage = getSubTexture(
            tex,
            weapons[i].x | 0,
            weapons[i].y | 0,
            weapons[i].w | 0,
            weapons[i].h | 0,
            weapons[i].ax,
            weapons[i].ay
        );

        subImage._index0 = weapons[i].index0 | 0;
        subImage._triangles = weapons[i].triangles | 0;
        subImage._vertex0 = weapons[i].vertex0 | 0;
        subImage._vertexCount = weapons[i].vertexCount | 0;
        subImage._vertices = vertexData;
        subImage._indices = indexData;

        //console.log(weapons[i].src);

        GAME_CFG.weapons[i+1].texture = subImage;
        GAME_CFG.weapons[i+1].icon = weapons[i].icon;
        GAME_CFG.weapons[i+1].scale = weapons[i].scale;*/

        let newWeapon: WeaponConfig =
        {
            ai_shootDistanceMax: weaponsData[i].ai_shootDistanceMax,
            ai_shootDistanceMin: weaponsData[i].ai_shootDistanceMin,
            angleSpread: weaponsData[i].angleSpread,
            angleVar: weaponsData[i].angleVar,
            bulletDamage: weaponsData[i].bulletDamage,
            bulletHp: weaponsData[i].bulletHp,
            bulletLifetime: weaponsData[i].bulletLifetime,
            bulletShellColor: weaponsData[i].bulletShellColor,
            bulletType: weaponsData[i].bulletType,
            cameraFeedback: weaponsData[i].cameraFeedback,
            cameraLookForward: weaponsData[i].cameraLookForward,
            cameraScale: weaponsData[i].cameraScale,
            cameraShake: weaponsData[i].cameraShake,
            clipReload: weaponsData[i].clipReload,
            clipSize: weaponsData[i].clipSize,
            criticalHitChance: weaponsData[i].criticalHitChance,
            detuneSpeed: weaponsData[i].detuneSpeed,
            gfxColor: weaponsData[i].gfxColor,
            gfxRot: weaponsData[i].gfxRot,
            gfxSx: weaponsData[i].gfxSx,
            handsAnim: weaponsData[i].handsAnim,
            kickBack: weaponsData[i].kickBack,
            laserSightColor: weaponsData[i].laserSightColor,
            laserSightSize: weaponsData[i].laserSightSize,
            launchTime: weaponsData[i].launchTime,
            moveWeightK: weaponsData[i].moveWeightK,
            name: weaponsData[i].name,
            offset: weaponsData[i].offset,
            relaunchSpeed: weaponsData[i].relaunchSpeed,
            reloadTime: weaponsData[i].reloadTime,
            spawnCount: weaponsData[i].spawnCount,
            velocity: weaponsData[i].velocity,
            velocityVar: weaponsData[i].velocityVar,
            skins: weaponsData[i].skins,
            textures: []
        }

        /*let j = 0;

        newWeapon.skins.map(async (skin: any) =>
        {
            const [imgage] = await Promise.all([loadImage(`${skin.src}.png`)]);
            const tex = createTexture(imgage.width, imgage.height);
            uploadTexture(tex, imgage);
            
            const subImage = getSubTexture(
                tex,
                skin.x | 0,
                skin.y | 0,
                skin.w | 0,
                skin.h | 0,
                skin.ax,
                skin.ay
            );

            subImage._index0 = skin.index0 | 0;
            subImage._triangles = skin.triangles | 0;
            subImage._vertex0 = skin.vertex0 | 0;
            subImage._vertexCount = skin.vertexCount | 0;
            subImage._vertices = vertexData;
            subImage._indices = indexData;

            newWeapon[i].textures[j] = subImage;

            console.log("newWeapon.skins.map");

            j++;
        });*/

        for(let j = 0 ; j < newWeapon.skins.length ; j++)
        {
            const [imgage] = await Promise.all([loadImage(`${newWeapon.skins[j].src}.png`)]);
            const tex = createTexture(imgage.width, imgage.height);
            uploadTexture(tex, imgage);
            
            const subImage = getSubTexture(
                tex,
                newWeapon.skins[j].x | 0,
                newWeapon.skins[j].y | 0,
                newWeapon.skins[j].w | 0,
                newWeapon.skins[j].h | 0,
                newWeapon.skins[j].ax,
                newWeapon.skins[j].ay
            );

            subImage._index0 = newWeapon.skins[j].index0 | 0;
            subImage._triangles = newWeapon.skins[j].triangles | 0;
            subImage._vertex0 = newWeapon.skins[j].vertex0 | 0;
            subImage._vertexCount = newWeapon.skins[j].vertexCount | 0;
            subImage._vertices = vertexData;
            subImage._indices = indexData;

            newWeapon.textures[j] = subImage;
        }

        GAME_CFG.weapons[i] = newWeapon;
    }

    //console.log(GAME_CFG.weapons);

    /*datasPlayer.avatar = Math.floor(Math.random() * Img.num_avatars);
    datasPlayer.weapon = Math.floor(Math.random() * (GAME_CFG.weapons.length - 1)) + 1;
    datasPlayer.weaponSkin = Math.floor(Math.random() * GAME_CFG.weapons[datasPlayer.weapon].skins.length);*/

    ////////////////////////////////////////////////////////

    res = await fetch(`${apiUrl}/api/items/get_all`);
    const itemsDatas = await res.json();

    for (let i = 0; i < itemsDatas.length; i++)
    {
        const [imgage] = await Promise.all([loadImage(`${itemsDatas[i].src}.png`)]);
        const tex = createTexture(imgage.width, imgage.height);
        uploadTexture(tex, imgage);
        
        const subImage = getSubTexture(
            tex,
            itemsDatas[i].x | 0,
            itemsDatas[i].y | 0,
            itemsDatas[i].w | 0,
            itemsDatas[i].h | 0,
            0.5,
            0.5
        );

        subImage._index0 = 0;
        subImage._triangles = 2;
        subImage._vertex0 = itemsDatas[i].vertex0 | 0;
        subImage._vertexCount = 51;
        subImage._vertices = vertexData;
        subImage._indices = indexData;

        const it: Item =
        {
            tex: subImage,
            scale: itemsDatas[i].scale
        };

        items[i] = it;
    }

    logo = getSubTexture(
        texture,
        170,
        66,
        94,
        39,
        0.5,
        0.5
    );

    logo._index0 = 0;
    logo._triangles = 2;
    logo._vertex0 = 2425;
    logo._vertexCount = 51;
    logo._vertices = vertexData;
    logo._indices = indexData;

    //////////////////////////////////

    let goToGear: string = "";

    for(let i = 0 ; i < avatarsDatas.length ; i++)
    {
        if(avatarsDatas[i].accessible)
        {
            goToGear = avatarsDatas[i].gotoGear.toLowerCase();
            break;
        }
    }

    const filteredIndices = GAME_CFG.weapons.reduce((indices, w, index) =>
    {
        if (w.name.toLocaleLowerCase() == goToGear)
        {
          indices.push(index);
        }

        return indices;
    }, []);

    if(filteredIndices.length != 0)
    {
        datasPlayer.weapon = filteredIndices[0];
    }
    else
    {
        datasPlayer.weapon = 0;
    }

    for(let i = 0 ; i < GAME_CFG.weapons[datasPlayer.weapon].skins.length ; i++)
    {
        if(GAME_CFG.weapons[datasPlayer.weapon].skins[i].accessible)
        {
            datasPlayer.weaponSkin = i;

            //skinsByCat[i] = i;

            //console.log(`Skin ${j} de catégorie ${i} accessible`);

            break;
        }
    }

    ////////////////////////////////////////////////////

    for(let i = 0 ; i < GAME_CFG.weapons.length ; i++)
    {
        for(let j = 0 ; j < GAME_CFG.weapons[i].skins.length ; j++)
        {
            if(GAME_CFG.weapons[i].skins[j].accessible)
            {
                skinsByCat[i] = j;
                break;
            }
            else
            {
                skinsByCat[i] = -1;

                //console.log(`Skin ${j} de catégorie ${i} inaccessible`);
            }
        }
    }

    ////////////////////////////////////////////////////

    //console.log(skinsByCat);

    for(let i = 0 ; i < avatars.length ; i++)
    {
        if(avatars[i].accessible)
        {
            datasPlayer.avatar = i;
        }
    }
};

export const loadSpotLightTexture = async (): Promise<void> => {
    const image = await loadImage("spot.png");
    imgSpotLight = createTexture(image.width, image.height);
    imgSpotLight._x = 0.5;
    imgSpotLight._y = 0.5;
    uploadTexture(imgSpotLight, image, GL.LINEAR);
};